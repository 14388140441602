/*****************************************************************************************
 * 설명 : 로그인 header
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { NavLink } from 'react-router-dom';

import logo from 'assets/images/admin/logo.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const CompanyHeaderLogin = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <div className="header-company">
      <NavLink to="/" className="logo">
        <img src={logo} alt="logo"/><span className="ml10">- 업체용</span>
      </NavLink>

      <div className="util-area">
        <div className="top-icon">
          <a href="http://www.ref.or.kr/" target="_blank" rel="noreferrer" title="홈페이지">
            <span className="material-icons right-icon">home</span>
          </a>
        </div>
      </div>
    </div>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default CompanyHeaderLogin;