/*****************************************************************************************
 * 설명 : 게시판 > 상세보기
 * URI : /board/view
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { baseURL } from 'config/config';
import Restful from 'service/restful';
import { formatBytes, getExtensionOfFilename } from 'service/utils';

import Layout from 'pages/homepage/layout/layout';
import SubHeader from "pages/homepage/layout/subHeader";

import './board.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const BoardView = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  let boardId = params.get('boardId') === null || params.get('boardId') === 'notice' ? 'notice' : 'qna';
  let boardName = params.get('boardId') === null || params.get('boardId') === 'notice' ? '공지사항' : '자주하는질문';

  const [data, setData]= useState({
    seq: 0,
    subject: '',
    contents: '',
    hit: '',
    createDate: ''
  });

  const [upload, setUpload] = useState([]);

  /***************************************************************************************
   * 설명 : 게시판 데이터 가져오기
  ***************************************************************************************/
  const getBoardData = (info) => {
    let params = {
      program: 'home',
      service: 'board',
      version: '1.0',
      action: 'getBoardView',
      boardId: info.boardId,
      boardSeq: info.seq
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result ) {
        setData(response.data.data);

        if( response.data.upload ) {
          setUpload(response.data.upload);
        } else {
          setUpload([]);
        }

      } else {
        setData([])
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    if( location.state ) {
      setData(location.state);

      getBoardData(location.state);

    } else {
      let tmp = {
        boardId: query.boardId,
        seq: query.seq
      }
      setData(tmp);

      getBoardData(tmp);
    }

    // eslint-disable-next-line
  }, [window.location])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <SubHeader depth1="고객센터" depth2={boardName}></SubHeader>

      <div className="board-home inner">
        <table className="list-table board-view-table">
          <colgroup>
            <col className="col1" />
            <col className="col2" />
          </colgroup>
          <tbody>
          <tr className="title-tr">
            <td colSpan={2}>
              <div className="title">{data.subject}</div>
              <div className="date">{data.createDate}<span>{data.userName}</span></div>
            </td>
          </tr>
          <tr className="board-contents">
            <td colSpan={2}>
              <div className="left" dangerouslySetInnerHTML={{__html: data.contents}}></div>
            </td>
          </tr>
          <tr className="file-tr">
            <th>첨부파일</th>
            <td>
              {
                upload.length > 0 && upload.map((item, index) => {
                  console.log(item);
                  if( ['JPG', 'JPEG', 'GIF', 'PNG', 'BMP', 'TIFF'].includes(getExtensionOfFilename(item.origin)) ) {
                    return <React.Fragment key={index}></React.Fragment>;
                  } else {
                    return (
                      <a
                        key={index}
                        href={baseURL + '/api/home/board/file_download.php?seq=' + item.seq}
                        target="_blank"
                        className="file"
                        rel="noreferrer"
                      >{item.origin} ({formatBytes(item.size)})</a>
                    )
                  }
                })
              }
            </td>
          </tr>
          </tbody>
        </table>

        <Button className="Btn list-btn" onClick={() => {navigate('/board/list?boardId='+ boardId)}}>목록</Button>
      </div>

    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default BoardView;