/*****************************************************************************************
 * 설명 : 아이디/비밀번호 찾기 - 아이디찾기결과
 * URI : /findId/result
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import Layout from 'pages/homepage/layout/layout';

import './findIdPw.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FindIdResult = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const location = useLocation();
  const [data, setData] = useState({});

  /**************************************************************************************
    설  명 : 데이터 로딩 시 처리
  ***************************************************************************************/
  useEffect(() => {
    setData(location.state);

    // eslint-disable-next-line
  }, [location.state])


  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="findIdPw inner">
        <div className="page-title">아이디/비밀번호 찾기</div>

        <div className="result-div">
          <div className="result-wrap">
            <div className="find-title">아이디 찾기 결과</div>
            <div className="id">{data?.id}</div>
            <Button variant="contained" className="HBtn blackbg" href="/login" fullWidth>로그인</Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FindIdResult;