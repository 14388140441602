import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

// ipv4 or ipv6
export const ipReg = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

// ipv4 single
export const ipv4Reg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

// ipv4 range (0.0.0.0-0.0.0.1)
export const ipv4RangeReg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(-{1}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$/;

// ipv4 single(/prefix)
export const ipv4PrefixReg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/{1}[0-9]{1,3})$/;

// netmask
export const netmask = /^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/;

// ipv4 single(/netmask)
export const ipv4NetmaskReg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/{1}(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3})))$/;

// ipv4 single(/prefix or /netmask)
export const ipv4PreNetReg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(((\/{1}[0-9]{1,3})?$)|(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))?$)/;

// ipv6 single
export const ipv6Reg = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/

// ipv6 prefix
export const ipv6PrefixReg = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(\/{1}[0-9]{1,3})$/


/********************************************************************************
 * 설명 : 1000 단위 콤마
 * 입력값 : num = 숫자
 * 리턴값 : 1000단위 콤마 숫자
*********************************************************************************/
export function comma(num) {
  let amt = ( num === null || num === undefined || num === '' ) ? 0 : num;

  if( amt.toString === null || amt.toString === undefined ) return num;
  else return amt.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

/********************************************************************************
 * 설명 : 0000 HHmm 시분 단위 값을 HH:MM 으로 리턴
 * 입력값 : time = 시분
 * 리턴값 : HH:MM
*********************************************************************************/
export function getTime(time, split = ':') {
  if( time === null || time === undefined ) return '';

  return time.substr(0, 2) + split + time.substr(-2);
}

/********************************************************************************
 * 설명 : 000000 HHmmss 시분 단위 값을 HH:MM 으로 리턴
 * 입력값 : time = 시분
 * 리턴값 : HH:MM:SS
*********************************************************************************/
export function getTimeSec(time, split = ':') {
  if( time === null || time === undefined ) return '';

  return time.substr(0, 2) + split + time.substr(2, 2) + split + time.substr(-2);
}

/********************************************************************************
  설명 : 그리드 데이터 변경 시 기존에 선택된 내역을 다시 선택한다.
  입력값 :
           api = gridApi,
           selectedData = 기존에 선택된 데이터 array
           keyField = 비교할 키 필드값
           afterFunc 선택 후 실행할 함수
  리턴값 : 없음
*********************************************************************************/
export async function selectedGrid(api, selectedData, keyField, selectFunc) {
  let returnData = '';

  // 기존에 선택된 그리드 선택
  if( selectedData.length > 0 ) {
    await api.forEachNode((item) => {
      selectedData.forEach((item1) => {
        if(item.data[keyField] === item1[keyField]) {
          item.setSelected(true);
          if( selectFunc !== null ) selectFunc(item.data)
          returnData = item.data;
        }
      });
    });

  // 기존에 선택된게 없으면 첫번째 데이터 선택
  } else {
    await api.forEachNode((item, index) => {
      if( index === 0 ) {
        item.setSelected(true);
        if( selectFunc !== null ) selectFunc(item.data);
        returnData = item.data;
      }
    });
  }

  return returnData;
}

/********************************************************************************
 * 설명 : select tag 용 데이터를 설정하여 리턴
 * 입력값 : data = 분리할 데이터, labelField = 라벨 필드명, valueField = 값에 사용할 필드명
 * 리턴값 : select tag 용 array
*********************************************************************************/
export function getSelectData(data, labelField, valueField) {
  if( data === null || data === undefined || data.length < 1 ) return [];

  let tmp = [];

  for(let item of data) {
    tmp.push({
      label: item[labelField],
      value: item[valueField],
      ...item
    });
  }

  return tmp;
}

/********************************************************************************
 * 설명 : 01234567 숫자로 되어 있는 요일을 한글(월~공)로 리턴
 * 입력값 : trainDay = 숫자 요일
 * 리턴값 : 한글 요일
*********************************************************************************/
export function getTrainDayName(trainDay) {
  let tmp = trainDay;
  if( tmp !== null && tmp !== undefined && tmp.replace !== undefined )
    tmp = tmp.replace('0', '월').replace('1', '화').replace('2', '수').replace('3', '목').replace('4', '금')
          .replace('5', '<span className="cblue">토</span>').replace('6', '<span className="cred">일</span>').replace('7', '<span className="cred">공</span>');

  return tmp;
}

/********************************************************************************
 * 설명 : 01234567 숫자로 되어 있는 요일을 한글(월~공)로 리턴
 * 입력값 : trainDay = 숫자 요일
 * 리턴값 : 한글 요일
*********************************************************************************/
export function getTrainDayNameTxt(trainDay) {
  let tmp = trainDay;
  if( tmp !== null && tmp !== undefined && tmp.replace !== undefined )
    tmp = tmp.replace('0', '월').replace('1', '화').replace('2', '수').replace('3', '목').replace('4', '금')
          .replace('5', '토').replace('6', '일').replace('7', '공');

  return tmp;
}

/********************************************************************************
 * 설명 : YYYYMMDD 형식을 YYYY-MM-DD로 리턴
 * 입력값 : YYYYMMDD
 * 리턴값 : YYYY-MM-DD
*********************************************************************************/
export function getDate(yyyymmdd, split = '-') {
  if( yyyymmdd === undefined || yyyymmdd === null || yyyymmdd === '' || yyyymmdd.length !== 8 ) return '';

  return yyyymmdd.substr(0, 4) + split + yyyymmdd.substr(4, 2) + split + yyyymmdd.substr(-2);
}

/********************************************************************************
 * 설명 : YYYYMMDDHHMISS 형식을 YYYY-MM-DD HH:MI:SS로 리턴
 * 입력값 : YYYYMMDDHHMISS
 * 리턴값 : YYYY-MM-DD HH:MI:SS
*********************************************************************************/
export function getDateTime(datetime, split = '-') {
  if( datetime === undefined || datetime === null || datetime === '' || datetime.length !== 14 ) return datetime;

  return (
    datetime.substr(0, 4) + split +
    datetime.substr(4, 2) + split +
    datetime.substr(6, 2) + ' ' +
    datetime.substr(8, 2) + ':' +
    datetime.substr(10, 2) + ':' +
    datetime.substr(12, 2)
  );
}

/********************************************************************************
 * 설명 : 해시 값 구하기
 * 입력값 : s = 문자열
 * 리턴값 : 해시 값
*********************************************************************************/
export function hashCode(s) {
  var tmp = String(s);
  return tmp.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
}


/*****************************************************************************************
 * 설명 : IPv4 입력 형식이 일치하는지 체크
 * 입력값 : IPv4 - single ip
 * 리턴값 : true/false
*****************************************************************************************/
export function validateIPv4(ipv4, single = true, range = false, prefix = false, netmask = false) {
  var singleCheck = false;
  var rangeCheck = false;
  var prefixCheck = false;
  var netmaskCheck = false;
  var tmpTest = false;

  // range 허용일 경우
  tmpTest = ipv4RangeReg.test(ipv4);
  rangeCheck = ( range === true && tmpTest === true ) ? true : false;

  // prefix 허용일 경우
  tmpTest = ipv4PrefixReg.test(ipv4);
  prefixCheck = ( prefix === true && tmpTest === true ) ? true : false;

  // netmask 허용일 경우
  tmpTest = ipv4NetmaskReg.test(ipv4);
  netmaskCheck = ( netmask === true && tmpTest === true ) ? true : false;

  // single 체크
  tmpTest = ipv4Reg.test(ipv4);
  singleCheck = ( single === true && tmpTest === true ) ? true : false;

  // 하나라도 true 일 경우 true
  if( rangeCheck || prefixCheck || netmaskCheck || singleCheck) return true;
  else return false;
}

/*****************************************************************************************
 * 설명 : 숫자로 된 휴대폰을 - - 를 추가하여 리턴
 * 입력값 : 숫자로 된 휴대폰
 * 리턴값 : - 가 들어간 휴대폰
*****************************************************************************************/
export function getHp(hp) {
  if( hp === undefined || hp.length < 10 ) return hp;

  hp = hp.replace(/-/gi, '');

  let hp1 = hp.substr(0, 3);
  let hp2 = ( hp.length === 10 ) ? hp.substr(3, 3) : hp.substr(3, 4);
  let hp3 = hp.substr(-4);

  return hp1 + '-' + hp2 + '-' + hp3;
}

/*****************************************************************************************
 * 설명 : SMS 발송으로 회원 리스트 보내기
 * 입력값 : gridApi = 회원 리스트 그리드API, memberList = 보낼 회원 리스트, dispatch
 * 리턴값 : 없음
*****************************************************************************************/
export function setSmsList(gridApi, memberList, dispatch) {
  let params = [];
  let selected = [];
  if( gridApi !== null && gridApi.getSelectedRows !== undefined ) selected = gridApi.getSelectedRows();
  let data = memberList;

  if( selected === undefined ) return;

  if( selected.length > 0 ) {
    data = selected;
  }

  data.map((item) => {
    // sms 리스트 발송 리스트에 추가
    params.push({
      memNo: item.memNo,
      memName: item.memName,
      hp: item.hp
    });

    return item;
  });

  dispatch({type: 'ADD_LIST', data: params});
}

/*****************************************************************************************
 * 설명 : table rowSpan 계산
 * 입력값 : data = 데이터, fieldName = 계산에 사용될 필드명
 * 리턴값 : 없음
*****************************************************************************************/
export function getRowSpan(data, fieldName) {
  if( data.length < 2 ) return data;

  let rowSpan = [];
  let index = 1;

  for(let i = 0; i < data.length; i++ ) {
    if( i === 0 ) {
      continue;

    } else if( i === data.length - 1) {
      if( data[i-1][fieldName] === data[i][fieldName] ) index++;
      rowSpan.push(index);

    } else if( data[i-1][fieldName] !== data[i][fieldName] ) {
      rowSpan.push(index);
      index = 1;

    } else {
      index++;
    }
  }

  index = 0;

  for(let i = 0; i < data.length ; i++ ) {
    if( i > 0 && data[i-1][fieldName] !== data[i][fieldName] ) index++;

    data[i].rowSpan = rowSpan[index];
  }

  return data;
}

/*****************************************************************************************
 * 설명 : 숫자를 한글로 리턴
 * 입력값 : number
 * 리턴값 : 한글
*****************************************************************************************/
export function num2han(num) {
  num = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + '';
  // 숫자/문자/돈 을 숫자만 있는 문자열로 변환
  if(num === '0') return '영';

  var number = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
  var unit = ['', '만', '억', '조'];
  var smallUnit = ['천', '백', '십', ''];
  var result = [];

  //변환된 값을 저장할 배열
  var unitCnt = Math.ceil(num.length / 4);

  //단위 갯수. 숫자 10000은 일단위와 만단위 2개이다.
  num = num.padStart(unitCnt * 4, '0')

  //4자리 값이 되도록 0을 채운다
  var regexp = /[\w\W]{4}/g;

  //4자리 단위로 숫자 분리
  var array = num.match(regexp);

  //낮은 자릿수에서 높은 자릿수 순으로 값을 만든다(그래야 자릿수 계산이 편하다)
  unitCnt = 0;
  for(var i = array.length - 1; i >= 0; i--, unitCnt++) {
    var hanValue = _makeHan(array[i]);
    //한글로 변환된 숫자
    if(hanValue === '') continue;
    //값이 없을땐 해당 단위의 값이 모두 0이란 뜻.

    result.unshift(hanValue + unit[unitCnt]);
    //unshift는 항상 배열의 앞에 넣는다.
  }

  //여기로 들어오는 값은 무조건 네자리이다. 1234 -> 일천이백삼십사
  function _makeHan(text) {
    var str = '';

    for(var i = 0; i < text.length; i++) {
      var num = text[i];

      //0은 읽지 않는다
      if(num === '0') continue;
      str += number[num] + smallUnit[i];
    }

    return str;
  }

  return result.join('');
}

/***************************************************************************************
   * 설명 : 부서 select 박스 구분별 띄어쓰기
  ***************************************************************************************/
 export const lpad = (str, padLen, padStr) => {
  if (padStr.length > padLen) {
    return str;
  }
  str += ""; // 문자로
  padStr += ""; // 문자로
  while (str.length < padLen) {
      str = padStr + str;
  }
  str = str.length >= padLen ? str.substring(0, padLen) : str;
  return str;
}

/***************************************************************************************
 * 설명 : 전화번호 하이픈 넣기
***************************************************************************************/
export const phoneNumber = (value, formik, name) => {
  if (!value) {
    return "";
  }

  value = value.replace(/[^0-9]/g, "");

  let result = [];
  let restNumber = "";

  if (value.startsWith("02")) {
    result.push(value.substr(0, 2));
    restNumber = value.substring(2);

  } else if (value.startsWith("1")) {
    restNumber = value;

  } else {
    result.push(value.substr(0, 3));
    restNumber = value.substring(3);
  }

  if (restNumber.length === 7) {
    result.push(restNumber.substring(0, 3));
    result.push(restNumber.substring(3));

  } else {
    result.push(restNumber.substring(0, 4));
    result.push(restNumber.substring(4));
  }

  let tel = result.filter((val) => val).join("-");

  return formik.setFieldValue([name], tel);
}

/***************************************************************************************
 * 설명 : 원 클릭 Ag-grid 셀 수정 하기
***************************************************************************************/
export function startEditingCell(event) {
  if( event.column.colDef.editable ) {
    // 달력 컴포넌트 일 경우는 원클릭 안함
    if( event.colDef.cellRenderer !== 'agDateInput' )
      event.api.startEditingCell({
        rowIndex: event.rowIndex,
        colKey: event.column.colId
      });
  }
}

/***************************************************************************************
 * 설명 : children 배열의 값을 검색해서 리턴
***************************************************************************************/
export function searchOf(array, fieldName, value) {
  if( ! Array.isArray(array) ) return -1;

  for(let i = 0; i < array.length; i++ ) {
    if( array[i][fieldName] === value )
      return array[i];

    else if( array[i].hasOwnProperty('children') ) {
      let result = searchOf( array[i]['children'], fieldName, value );
      if( result !== -1 ) return result;
    }
  }

  return -1;
}

/***************************************************************************************
 * 설명 : 모달 이동
***************************************************************************************/
export const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

/***************************************************************************************
 * 설명 : 모달 이동
***************************************************************************************/
export const PaperComponentSub = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title-sub"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

/***************************************************************************************
 * 설명 : 한글은 2, 영문은 1
***************************************************************************************/
export const getTextLength = (str) => {
  var len = 0;
  for (var i = 0; i < str.length; i++) {
    if (escape(str.charAt(i)).length === 6) {
      len++;
    }
    len++;
  }
  return len;
}

/***************************************************************************************
 * 설명 : 용량 표시('Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB')
***************************************************************************************/
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/***************************************************************************************
 * 설명 : 파일 확장자 가져오기
***************************************************************************************/
export function getExtensionOfFilename(filename) {
  if( filename === undefined ) return;
  var _fileLen = filename.length;

  /**
   * lastIndexOf('.')
   * 뒤에서부터 '.'의 위치를 찾기위한 함수
   * 검색 문자의 위치를 반환한다.
   * 파일 이름에 '.'이 포함되는 경우가 있기 때문에 lastIndexOf() 사용
   */
  var _lastDot = filename.lastIndexOf('.');

  // 확장자 명만 추출한 후 소문자로 변경
  var _fileExt = filename.substring(_lastDot + 1, _fileLen).toUpperCase();

  return _fileExt;
}

/***************************************************************************************
 * 설명 : 영문, 한글을 제외한 입력 체크 - 숫자, 특수문자
***************************************************************************************/
export const isNotNumber = (value) => {
  const regExp = /[a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
  return regExp.test(value);
}

/***************************************************************************************
 * 설명 : 숫자 입력인지 체크
***************************************************************************************/
export const onlyNumber = (value) => {
  var str = String(value);
  var regex = /[^0-9]/g;
  var result = str.replace(regex, "");
  return result;
}

/***************************************************************************************
 * 설명 : 숫자, 소수점 입력인지 체크
***************************************************************************************/
export const onlyNumberDot = (value, decimalDigits = 2) => {
  var str = String(value);
  var regex = /[^0-9.]+|(\.(?=.*\.))/g;
  // 소수점 두 자리까지만 유효하게 표시합니다.
  var result = str.replace(regex, "").replace(new RegExp(`(\\.\\d{${decimalDigits}})\\d+`, 'g'), '$1');
  return result;
}

/***************************************************************************************
 * 설명 : 주민등록번호 앞 8 자리를 입력받아 표시 처리
 * 입력값 : value = 19950101
 *          gender = M / F
 * 리턴값 : 950101-1******
***************************************************************************************/
export const memberRegisterNumber = (value, gender) => {
  if( (value ?? '') === '' ) return;
  if( ! ['M', 'F'].includes(gender) ) return;
  let tmp = value.substr(2, 6) + '-';
  if( value.substr(0, 1) === '1' ) {
    if( gender === 'M' )
      return tmp + '1******';
    else
      return tmp + '2******';
  } else {
    if( gender === 'M' )
      return tmp + '3******';
    else
      return tmp + '4******';
  }
}