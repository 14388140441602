/*****************************************************************************************
 * 설명 : 팝업 레이아웃
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
// import { useHistory } from "react-router";

import moment from 'moment';
import { Button } from '@mui/material';

import Restful from "service/restful";

import './popup.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const PopupLayout = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const cookies = new Cookies();
  const [getApi] = Restful();

  const [popupList, setPopupList] = useState([]);
  const [popupNo, setPopupNo] = useState(0);


  /*******************************************************************************
    설  명 : 팝업 오늘 더 이상 보지 않기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  const setPopupToday = ( row ) => {
    // 만기일 1일
    var expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 1);
    cookies.set(row.seq, row, {expires: expireDate})

    row.isPopup = false;
    setPopupList([...popupList, row]);
    setPopupList(popupList.filter(popupList => popupList.isPopup === 1));
  }

  const setClose = ( row ) => {
    row.isPopup = false;
    setPopupList([...popupList, row]);
    setPopupList(popupList.filter(popupList => popupList.isPopup === 1));
  }

  /*******************************************************************************
    설  명 : 팝업 오늘 더 이상 보지 않기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  const getPopupToday = ( boardSeq ) => {

    let data = cookies.get(boardSeq);

    if( data ) {
      return false;
    } else return true;
  }

  const onClick = (row) => {
    // history.push('/board/view?boardId='+ row.boardId, {row})

    row.isPopup = 0;
    setPopupList({...popupList, row})
  }

  /*******************************************************************************
    설  명 : popup prev Btn Click
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  const prevPopup = () => {

    if (popupNo > 0) {
      setPopupNo(popupNo - 1);
    }
  }

  /*******************************************************************************
    설  명 : popup prev Btn Click
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  const nextPopup = () => {
    if (popupNo < (popupList.length - 1)) {
      setPopupNo(popupNo + 1);
    }
  }

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  useEffect(() => {
    let param = {
      program: 'home',
      service: 'board',
      version: '1.0',
      action: 'getBoardNotice',
      boardId: 'popup',
      searchText: '',
      searchType: ''
    }

    let tmp = [];

    getApi("get", param).then( response => {
      if( response !== undefined && response.data.result && response.data.data.length > 0 ) {
        // setPopupList(response.data.data);
        response.data.data.forEach(row => {
          if( row.noticeSdate <= moment().format('YYYY-MM-DD') && row.noticeEdate >= moment().format('YYYY-MM-DD')) {
            if( getPopupToday(row.seq) ) {
              if( parseInt(row.isPopup) === 1 ) {
                tmp.push(row);

              }
            }
          }
          setPopupList([...tmp]);
        })

      }
    });

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
   popupList.length > 0 &&
      <section className="popup-wrap">
        <div className="popup-background"></div>
        <div className="popup-left-control popup-control" onClick={() => prevPopup()}></div>
        <div className="popup-right-control popup-control" onClick={() => nextPopup()}></div>
        { popupList.map((row, i) => {
          return (
            <section key={i}>
              { popupNo === i &&
                <div id={`popup${row.seq}`}>
                  <div
                    className={`popup-layer ${row.isPopup === false ? 'hidden' : ''}`}
                    style={{'width':row.popupWidth+'px', 'height':((row.popupHeight*1)+60)+'px'}}
                  >
                    <div className="popup-header">{row.subject}
                      <Button type="button" className="close-btn fr mt10" onClick={() => setClose(row)}>
                        <span className="myicons-close"></span>
                      </Button>
                    </div>
                    <div className="popup-preview-contents cp" dangerouslySetInnerHTML={{__html: row.contents}} onClick={() => onClick(row)}></div>

                    <div className="popup-button-area">
                      <div className="fl">
                        <label className="popup-not-today">
                          <input type="checkbox" name="checkBtn" onClick={() => setPopupToday(row)} /> 오늘 더 이상 보지 않음
                        </label>
                      </div>
                      <div className="fr">
                        <button type="button" className="btn" onClick={() => setClose(row)}>창닫기</button>
                      </div>
                      <div className="cb"></div>
                    </div>
                  </div>
                </div>
              }
            </section>
          )
        })}
      </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default PopupLayout;
