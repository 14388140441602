/*****************************************************************************************
 * 설명 : 회원가입 > 약관동의
 * URI : /join/step1
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { baseURL } from 'config/config';

import Layout from 'pages/homepage/layout/layout';

import './join.scss';
// import { useHistory } from 'react-router';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const JoinStep1 = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const navigate = useNavigate();
  const popup = useRef(null);

  const [isAgree, setIsAgree] = useState({
    isService: false,
    isPrivacy: false
  });

  const onChange = (e) => {
    setIsAgree({
      ...isAgree,
      [e.target.name]: e.target.checked
    })
  }

  /***************************************************************************************
   * 설명 : 본인인증
  ***************************************************************************************/
  const submit = () => {
    if( isAgree.isPrivacy && isAgree.isService ) {
      if(window.location.host.indexOf('localhost') > -1 ) {
        navigate('/join/step2', {state: {
          authtype: 'M',
          birthdate: '19770215',
          gender: '1',
          conninfo: 'MC0GCCqGSIb3DQIJAyEArHitxURQLXPbUX5FZdE2MwVEpWWXe8OgDi4JB0DqIpU=',
          name: '송영석',
          requestnumber: 'G6870_20240313020230283'
        }});
      } else {
        popup.current = window.open( baseURL + '/api/checkplus/checkplus_main.php?type=', 'Parent_window', 'width=500,height=550,scrollbar=yes' );
      }
    }
  }

  /***************************************************************************************
   * 설명 : 메시지 처리
  ***************************************************************************************/
  const receiveMessage = (event) => {
    // if( queue.current.length > 0 ) return;
    // queue.current.push(event);

    /**
     * * TODO 향후 휴대폰 중복키값 체크
     */

    if( event.origin === window.location.origin ) {
      if( (event.data?.data ?? '') !== '' ) {
        const jsonData = JSON.parse(event.data.data);

        window.removeEventListener("message", receiveMessage);

        navigate('/join/step2', {state: jsonData});
      }
    }
  }

  /***************************************************************************************
   * 설명 : 윈도우 메시지 시작
  ***************************************************************************************/
  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);

    return () => {
      window.removeEventListener("message", receiveMessage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="join-home">
        <div className="inner">
          <div className="sub-header clearfix">
            <div className="page-title">회원가입</div>
            <ul className="path clearfix">
              <li className="active">약관동의</li>
              <li>정보입력</li>
              <li>가입완료</li>
            </ul>
          </div>

          <div className="join-step1 guide">
            <div className="h3">회원약관 및 개인정보보호정책</div>
            <div className="txt">등록하신 회원정보는 회원님의 동의 없이 공개 및 유출되지 않으며, 개인정보보호정책에 의거하여 보호받고 있습니다.</div>
            <div className="terms">
              [자격기본법 시행령]<br/>
              [시행 2015.1.1.] [대통령령 제25840호, 2014.12.9., 타법개정]<br/>
              제32조(자격에 관한 정보의 내용 등)<br/>
              ① 법 제34조제1항에 따라 국가자격관리자와 공인자격관리자는 다음 각 호의 사항에 대한 정보를 체계적으로 관리하여야 한다.<br/>
              1. 성명ㆍ주민등록번호 등 자격취득자의 인적사항<br/>
              2. 최종학교명, 전공 등 자격취득자의 학력사항<br/>
              3. 자격의 종목ㆍ분야 및 취득연도 등 취득한 자격에 관한 사항<br/>
              4. 자격의 취소ㆍ정지 및 말소에 관한 사항<br/>
              ② 중앙행정기관의 장은 제1항에 따라 관리하는 정보의 내용을 자격정책의 수립 및 자격제도의 개선 등에 활용하여야 한다.
            </div>

            <div className="h3">서비스 이용약관</div>
            <div className="terms">
              제1장 총 칙<br/>
              <br/>
              제1조 [목적]<br/>
                이 약관은 민간자격관리자와 민간자격을 취득하려는 자 간의 민간자격 취득에 관한 교육훈련, 자격검정 등의 제반사항을 규정하는 것을 목적으로 합니다.<br/>
                <br/>
              제2조 [정의]<br/>
              이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
              <br/>
              1. “민간자격”이란 「자격기본법」에 따라 국가 외의 자가 신설하여 관리·운영하는 자격을 말합니다.<br/>
              2. “민간자격관리자”는 「자격기본법」에 따라 민간자격을 관리?운영하는 자를 말합니다.<br/>
              3. “자격검정”이란 「자격기본법」에 따라 자격을 부여하기 위하여 필요한 직무수행능력을 평가하는 과정을 말합니다.<br/>
              4. “보수교육”이란 민간자격 취득과 관련하여 민간자격관리자가 제공하는 교육을 말합니다.<br/>
              5. 민간자격을 취득하려는 자는 그 취득방식에 따라 다음 각 목의 지위 중 어느 하나 또는 양 지위 모두를 가집니다.<br/>
                가. 응시자: 민간자격 취득을 위해 민간자격관리자가 시행하는 자격검정시험에 응시하는 자<br/>
                <br/>
              제3조 [관계법령 및 개별약정]<br/>
              <br/>
                이 약관에 규정되지 아니한 사항에 대해서는 「자격기본법」,「소비자기본법」,「약관의 규제에 관한 법률」,「할부거래에 관한 법률」,「방문판매 등에 관한 법률」,「전자상거래 등에서의 소비자보호에 관한 법률」,「민법」등 관계법령에 따릅니다. 또한 관계법령 내 강행규정에 반하지 않는 한, 민간자격관리자와 민간자격을 취득하려는 자가 개별적으로 약정한 바에 따릅니다.<br/>
                <br/>
              제4조 [약관의 효력]<br/>
              <br/>
              ① 민간자격관리자는 일반적으로 예상되는 방법(예컨대, 약관을 오프라인 또는 온라인상으로 보기 쉽게 게시하거나, 전자우편 등 기타의 방법)으로 약관의 내용을 분명하게 밝히고 민간자격을 취득하려는 자가 요구할 경우 그 약관의 사본을 내주어 민간자격을 취득하려는 자가 약관의 내용을 알 수 있게 합니다.<br/>
              <br/>
              ② 민간자격관리자는 다음 각 호의 사항 및 수강료, 응시료, 환급기준 등 약관에 정하여져 있는 중요한 내용을 민간자격을 취득하려는 자가 이해할 수 있도록 설명합니다.<br/>
              <br/>
                1.「자격기본법」제2조 제5의2호‘등록자격’이나 제2조 제5의3호‘공인자격’해당 여부 및 자격의 등록 또는 공인번호<br/>
                2. 해당 자격을 관리?운영하는 민간자격관리자의 명칭(개인인 경우는 성명) 및 전화번호<br/>
                3. 자격취득 또는 자격검정 등에 드는 총비용과 그 세부 내역 및 환급에 관한 사항(총비용은 민간자격을 취득하려는 자가 해당 민간자격관리자에게 납부하는 일체의 비용을 말합니다.)<br/>
                <br/>
              ③ 민간자격관리자가 제1항 및 제2항을 위반하여 계약을 체결한 경우에는 약관을 계약의 내용으로 주장할 수 없습니다.<br/>
              <br/>
              제5조 [약관의 개정]<br/>
              <br/>
              ① 민간자격관리자는 필요한 경우 이 약관을 관련 법령 내 강행규정에 위배되지 않는 범위 내에서 개정할 수 있으며, 이 경우 적용일자, 개정사유를 민간자격을 취득하려는 자에게 설명하고 약관의 개정 전·후를 비교하여 민간자격을 취득하려는 자가 알기 쉽게 통지합니다.<br/>
              <br/>
              ② 제1항의 통지는 그 적용일자 7일 전에 이루어져야 합니다. 다만 민간자격을 취득하려는 자에게 불리하게 약관의 내용을 변경하는 경우에는 최소한 30일 이상의 사전유예기간을 두고 통지합니다.<br/>
              <br/>
              ③ 민간자격관리자는 개정약관 통지 후 개정약관의 적용에 대한 민간자격을 취득하려는 자의 동의 여부를 확인합니다. 민간자격을 취득하려는 자가 개정약관의 적용에 동의하지 않는 경우 계약을 해지할 수 있습니다.<br/>
              <br/>
              제6조 [민간자격관리자의 의무]<br/>
              <br/>
                민간자격관리자는 이 약관의 규정, 관계법령 및 기타 미풍양속에 반하는 행위를 하지 않고, 개인정보 보호정책을 공시·준수하며, 민간자격을 취득하려는 자로부터 제기된 의견이나 불만이 정당하다고 인정할 경우 이를 처리해야 합니다.<br/>
                <br/>
              제7조 [민간자격을 취득하려는 자의 의무]<br/>
              <br/>
                민간자격을 취득하려는 자는 이 약관의 규정, 관계법령 및 민간자격관리자가 통지 또는 공지하는 사항 등을 준수하여야 하며, 민간자격관리자의 업무에 방해되는 행위를 하여서는 안 됩니다.<br/>
                <br/>
              제2장 자격검정에 관한 사항<br/>
              <br/>
              제8조 [자격검정에 관한 정보제공]<br/>
              <br/>
                민간자격관리자는 자격검정시험에 응시하려는 자가 응시신청을 하기 전에 다음 각 호의 내용을 알립니다.<br/>
                1. 이 약관 제4조 제2항 제1호 내지 제3호에 따른 자격정보<br/>
                2. 응시자격 및 시험과목<br/>
                3. 원서접수 등 응시방법, 응시신청 철회방법, 시험결과 및 합격자 조회 등 자격검정시험과 관련된 정보<br/>
                4. 기타 응시자에게 필요한 사항<br/>
                <br/>
              제9조 [응시신청]<br/>
              <br/>
              ① 자격검정시험에 응시하려는 자는 민간자격관리자가 정한 기간 및 방법에 따라 응시신청을 할 수 있습니다.<br/>
              <br/>
              ② 응시자는 응시원서를 제출하고 응시료 및 다음 각 호의 증빙서류(이하‘증빙서류 등’이라 한다)를 제출하여야 합니다.<br/>
              <br/>
                  1. 응시자격 증명 관련 서류<br/>
                  2. 일부 과목 면제 관련 서류(해당자에 한함)<br/>
                  3. 기타 자격검정시험 응시를 위한 증빙서류<br/>
                  <br/>
              ③ 응시하려는 자가 전항의 응시료 및 증빙서류를 정해진 기간 내에 제출하지 않은 경우에는 해당 자격검정시험 응시가 거부될 수 있습니다.<br/>
              <br/>
              제10조 [응시료 등]<br/>
              <br/>
              ① 민간자격관리자는 자격검정시험에 응시하려는 자가 응시신청을 할 때, 응시료를 청구합니다.<br/>
              <br/>
              ② 자격검정시험에 응시하려는 자는 응시료를 신용카드로 납부할 수 있습니다.<br/>
              <br/>
              제11조 [수험표 교부 및 시험안내]<br/>
              <br/>
                민간자격관리자는 제9조 제1항 내지 제2항에 따라 응시료 및 증빙서류 등을 받은 때에는 응시자에게 영수증 및 다음 각 호의 사항이 기재된 수험표를 교부합니다.<br/>
              1. 시험 일자?시간 및 고사장<br/>
              2. 시험 준비물<br/>
              3. 기타 자격검정시험 관련 중요 사항<br/>
              <br/>
              제12조 [응시자 정보의 변경]<br/>
              <br/>
                응시자는 자격검정시험 응시신청시 제출한 정보가 시험일 전에 변경되었을 경우에는, 민간자격관리자에게 수정을 요청하여야 하며 수정요청을 하지 않아 발생되는 문제의 책임은 응시자에게 있습니다.<br/>
                <br/>
              제13조 [응시자의 계약 해제]<br/>
              <br/>
              ① 응시자는 다음 각 호의 사유가 있을 때는 응시계약을 해제할 수 있습니다.<br/>
                  1. 본인의 의사로 자격검정시험 응시를 포기한 경우<br/>
                  2. 허위사실 또는 허위광고 등에 의한 응시계약의 체결<br/>
                  3. 민간자격관리자가 제8조에 따른 중요사항을 응시신청 전에 알리지 않은 경우<br/>
                  4. 민간자격관리자의 귀책사유로 인한 응시계약 불이행 및 불완전이행이 있는 경우<br/>
                  <br/>
              ② 다음 각 호의 사유에 의해 시험 응시가 불가할 경우, 응시자는 응시계약을 해제할 수 있습니다.<br/>
              <br/>
                  1. 국가의 비상사태 또는 천재지변(지진, 화재, 폭우, 폭설 등)으로 시험을 시행 또는 진행할 수 없는 경우<br/>
                  2. 시험 진행 중 방송사고 등으로 시험이 더 이상 진행되지 못하고 중단된 경우(단, 결시자는 해당 사항 없음)<br/>
                  <br/>
              ③ 다음 각 호의 사유에 의해 시험 응시가 불가할 경우, 응시자는 응시신청 기간이 지난 후에도 응시계약을 해제할 수 있습니다.<br/>
              <br/>
                  1. 응시자 본인의 군입대: 군입대일이 시험일 이전이어야 하며 입영통지서 또는 병역수첩 사본 등 관련 증빙자료 제출<br/>
                  2. 응시자의 직계가족(친가 혹은 처가측 조부모, 부모, 형제, 자매, 배우자, 자녀에 한함)이 사망한 경우: 본인과의 가족관계가 나와 있는 공식서류와 사망을 알 수 있는 서류 제출<br/>
                  3. 응시자의 사고, 질병으로 인한 입원: 시험일이 입원기간 내에 포함되어야 하며, 입원을 입증하는 서류 제출<br/>
                  <br/>
                  <br/>
              제14조 [응시자의 계약 해제의 효과]<br/>
              <br/>
              ① 제13조 제1항 내지 제2항의 해제는 응시자가 민간자격관리자에 그 의사를 표시한 때(전자우편을 포함한 서면의 경우 그 의사표시가 적힌 서면을 발송한 날)에 효력이 발생합니다. 민간자격관리자는 응시자의 해제의 의사표시를 받은 즉시 이러한 사실을 응시자에게 회신합니다.<br/>
              <br/>
              ② 제13조 제1항 제1호에 따라 응시자가 응시계약을 해제한 경우, 다음 각 호의 기준에 따라 응시자의 해제의 의사표시를 받은 날로부터 3영업일 이내에 응시료를 환급합니다(10원 단위 절사, 해당 기간의 종료는 마감일의 23:59:59 까지).<br/>
              <br/>
                  1. 원서 접수마감 전까지 100%환불 (환불신청서 필요)<br/>
                  2. 원서 출력기간 전까지 다음 회차 1회 연기 (환불 안됨)<br/>
                  3. 원서 출력기간 부터는 연기 및 환불 불가능<br/>
                  <br/>
              ③ 제13조 제1항 제2호에 따라 응시자가 응시계약을 해제한 경우, 민간자격관리자는 응시자의 해제의 환불신청서를 받은 날로부터 3영업일 이내에 응시료 전액을 환급합니다.<br/>
              <br/>
              ④ 제13조 제3항에 따라 응시자가 응시계약을 해제하고 응시자가 수험표 및 해당 증빙자료를 제출하면, 민간자격관리자는 해제의 의사표시와 증빙자료를 제출받은 날로부터 3영업일 이내에 응시 회차를 다음회로 1회 한해 연기합니다.<br/>
              <br/>
              <br/>
              제15조 [민간자격관리자의 계약 해제 및 그 효과]<br/>
              <br/>
              ① 민간자격관리자는 다음 각 호의 사유에 해당하는 경우 자격검정시험에 관한 응시계약을 해제할 수 있습니다.<br/>
              <br/>
                  1. 응시자가 다른 사람의 명의로 응시한 경우<br/>
                  2. 응시원서의 내용을 허위로 기재하거나 허위 증빙서류를 제출하여 응시한 경우<br/>
                  3. 시험 감독자의 정당한 지시에 불응하는 행위를 하는 경우<br/>
                  4. 시험 개시 전 또는 종료 후 답안을 마킹하는 경우<br/>
                  5. 신분증을 지참하지 않고 시험에 응시한 경우<br/>
                  6. 기타 응시자의 응시자격 요건이 미비되었을 경우 또는 시험 진행에 방해되는 부정한 행위를 한 경우<br/>
                  <br/>
              ② 제1항에 따라 응시계약이 해제된 경우에는 응시료 등 응시자가 민간자격관리자에게 납부한 비용을 반환하지 않습니다.<br/>
              <br/>
              제16조 [손해배상]<br/>
              <br/>
              제14조 내지 제15조 규정은 손해배상의 청구에 영향을 미치지 않습니다.<br/>
              <br/>
              <br/>
              <br/>
              제3장 교육훈련에 관한 사항<br/>
              <br/>
              제1절 오프라인 교육훈련<br/>
              <br/>
              <br/>
              제28조 [교육훈련에 관한 정보제공]<br/>
              <br/>
              ① 민간자격관리자는 교육훈련을 받으려는 자가 수강신청을 하기 전에 다음 각 호의 사항을 알리고 학습자가 보기 쉬운 곳에 다음 각 호의 사항을 게시합니다.<br/>
              <br/>
                  1. 이 약관 제4조 제2항 제1호 내지 제4호에 따른 자격정보<br/>
                  2. 교육훈련 과목 및 과정의 현황과 개요<br/>
                  3. 교육훈련의 총 기간 및 일별(또는 주간별) 학습시간<br/>
                  4. 교육훈련을 위한 강사의 인적사항<br/>
                  5. 교육훈련을 위한 강습의 수강료 및 일체의 부대비용(수강증 (재)교부비, 교재비, 실습재료비 등)<br/>
                  6. 이 약관<br/>
                  7. 기타 학습자에게 필요한 사항<br/>
                  <br/>
              ② 민간자격관리자는 전항 각호의 사항을 허위로 게시하지 않습니다.<br/>
              <br/>
              제29조 [수강신청]<br/>
              <br/>
              ① 교육훈련을 받으려는 자는 민간자격관리자가 정한 절차에 따라 교육훈련의 수강신청을 하여야 하며, 수강신청서를 제출하고 수강료 및 일체의 부대비용(이하‘수강료등’이라 합니다)을 납부하여야 합니다.<br/>
              ② 민간자격관리자는 교육훈련을 받으려는 자가 수강신청을 하기 전에 수강료 이외에 교재비, 실습재료비 등의 부대비용을 부담해야 하는 경우에는 그 내역을 상세하게 고지합니다.<br/>
              ③ 민간자격관리자는 수강신청서와 수강료등을 받은 때는 교육훈련을 받으려는 자에게 영수증 및 다음 각 호의 사항이 기재된 수강증을 교부합니다.<br/>
              <br/>
                  1. 교육훈련 과정 및 과목<br/>
                  2. 교육훈련 시간<br/>
                  3. 기타 교육훈련에 관한 중요사항<br/>
                  <br/>
              제30조 [수강료 등]<br/>
              <br/>
              ① 민간자격관리자는 교육훈련을 받으려는 자가 수강신청을 할 때 수강료등을 청구합니다.<br/>
              ② 제29조 제2항에 의해 민간자격관리자가 고지하지 않은 수강료등은 교육훈련을 받으려는 자 또는 학습자(이하‘학습자등’이라 합니다)에게 청구할 수 없으며, 고지한 금액을 초과하여 징수하지 않습니다.<br/>
              ③ 학습자등은 수강료등을 신용카드로 납부할 수 있습니다.<br/>
              ④ 교재비는 학습자등이 교재의 구입을 원하는 경우에만 청구합니다.<br/>
              <br/>
              제31조 [수강증]<br/>
              <br/>
              ① 학습자는 교육훈련 기간 동안 민간자격관리자의 요구가 있을 때에는 수강증을 제시하여야 합니다.<br/>
              ② 학습자가 수강증을 분실한 경우에는 지체없이 민간자격관리자에게 그 사실을 통지하면, 민간자격관리자는 허위신고?부당사용 등의 특별한 사유가 없는 한 재교부합니다.<br/>
              ③ 학습자는 수강증을 부당하게 사용해서는 안 되며, 이를 위반하는 경우에는 민간자격관리자가 교육훈련을 받을 수 있는 자격을 박탈할 수 있습니다. 이 경우 학습자가 납부한 기수강료는 제38조 제2항에 따라 환급합니다.<br/>
              <br/>
              제32조 [학습자 정보의 변경]<br/>
              <br/>
                학습자는 수강신청시 제출한 정보가 교육훈련 중에 변경되었을 경우에는, 민간자격관리자에게 수정을 요청하여야 하며 수정요청을 하지 않아 발생되는 문제의 책임은 학습자에게 있습니다.<br/>
                <br/>
              제33조 [교육훈련 시간 및 강사]<br/>
              <br/>
              ① 민간자격관리자는 교육훈련 과정과 과목, 강습시간, 강사 등을 구체적으로 정하고 이를 성실히 준수합니다.<br/>
              ② 민간자격관리자는 예정된 강습시간 또는 강사를 임의로 변경할 수 없습니다. 다만, 불가피하게 변경하는 경우에는 사전에 이를 학습자에게 고지합니다.<br/>
              ③ 학습자는 제2항 단서의 규정에 의해 변경된 강습시간에 또는 변경된 강사에게 수강하는 것을 원하지 않는 경우에는 그 변경된 강습개시 전에 계약을 해지할 수 있습니다.<br/>
              <br/>
              제34조 [교육훈련의 연기]<br/>
              <br/>
              ① 학습자등은 수강신청 후 부득이한 사정으로 교육훈련을 받을 수 없게 된 경우에는 강습개시일 전에 수강의 연기를 신청할 수 있습니다.<br/>
              ② 학습자등은 수강의 연기를 신청하기 위해서는 그 사유를 기재한 신청서와 수강증을 민간자격관리자에게 제출하여야 합니다.<br/>
              ③ 민간자격관리자는 제2항의 연기신청서를 받은 경우에는 다음 번 강습과정(과목)을 개설하기가 어려운 사정이 있는 경우를 제외하고는 수강 연기를 허락합니다.<br/>
              ④ 민간자격관리자가 수강의 연기를 허락한 경우에는 제29조 제3항에 따라 교부한 수강증의 강습시간 등을 변경?기재하여 이를 학습자등에게 재교부합니다.<br/>
              <br/>
              제36조 [학습자등의 계약 해제?해지]<br/>
              <br/>
              ① 학습자등은 다음 각 호의 사유가 있을 때 교육훈련 수강에 대한 계약을 해제 또는 해지할 수 있습니다.<br/>
              <br/>
                  1. 본인의 의사로 교육훈련 수강을 포기한 경우<br/>
                  2. 제5조 제3항에 따른 개정약관의 적용에 동의하지 않는 경우<br/>
                  3. 민간자격관리자가 수강신청 전에 제28조 제1항 각호의 사항을 알리지 않거나 허위로 게시한 경우<br/>
                  4. 허위사실 또는 허위광고 등에 의한 계약의 체결<br/>
                  5. 무자격 또는 자격미달의 강사에 의한 강습<br/>
                  6. 제30조 제2항을 위반한 수강료등의 초과징수<br/>
                  7. 제33조에 따라 변경된 강습시간에 또는 변경된 강사에게 수강하는 것을 원하지 않는 경우<br/>
                  8. 교육훈련기관 또는 시설(이하 ‘교육훈련시설등’이라 한다)에 대한 행정처분(인가?등록의 취소, 폐지 등)<br/>
                  9. 교육훈련시설등의 이전이나 폐강<br/>
                10. 정원을 초과한 학습자의 강습<br/>
                11. 기타 수강을 현저히 곤란하게 할 정도의 부당한 강습 또는 민간자격관리자의 귀책사유에 의하여 학습자가 수강을 계속할 수 없는 경우<br/>
                <br/>
              ② 제1항의 해제 또는 해지는 학습자등이 민간자격관리자에 그 의사를 표시한 때(전자우편을 포함한 서면의 경우 그 의사표시가 적힌 서면을 발송한 날)에 효력이 발생합니다. 민간자격관리자는 학습자등의 해제 또는 해지의 의사표시를 받은 즉시 이러한 사실을 학습자등에게 회신합니다.<br/>
              <br/>
              제37조 [민간자격관리자의 계약 해지 및 그 효과]<br/>
              <br/>
              ① 민간자격관리자는 다음 각 호의 사유에 의해 학습자와의 계약을 해지할 수 있습니다.<br/>
              <br/>
                  1. 학습자가 강사의 교육지도에 따르지 않고 교육훈련에 관한 질서를 문란하게 하는 경우<br/>
                  2. 학습자의 품행이 불량하여 다른 학습자의 교육훈련에 지장을 초래하는 경우<br/>
                  3. 하나의 수강증으로 여러 학습자가 같은 강의를 여러 번 듣는 등 수강증을 부당하게 사용하는 경우<br/>
                  4. 기타 학습자의 책임있는 사유로 교육훈련에 관한 계약관계를 유지할 수 없을 정도로 민간자격관리자와의 신뢰관계를 저버린 경우<br/>
                  <br/>
              ② 민간자격관리자가 제1항의 사유로 계약의 해지를 한 경우, 지체없이 제37조 제2항 제2호에 해당하는 금액을 학습자에게 환급합니다. 단, 이 경우 교재 또는 실습재료의 반환비용은 학습자가 부담합니다.<br/>
              <br/>
              <br/>
              제39조 [손해배상]<br/>
              <br/>
              제37조 내지 제38조 규정은 손해배상의 청구에 영향을 미치지 않습니다.<br/>
              <br/>
              제40조 [교육훈련의 중단]<br/>
              <br/>
              ① 민간자격관리자는 국가의 비상사태 또는 천재지변(지진, 화재, 폭우, 폭설 등) 등 불가항력적인 사유가 발생한 경우, 임시휴강 또는 강습중단을 할 수 있습니다. 이 경우 학습자는 제4항에 따른 환급금 이외에 별도의 손해배상을 민간자격관리자에게 청구할 수 없습니다.<br/>
              ② 민간자격관리자가 강습을 임시휴강한 경우에는 당해 불가항력적 사유가 종료한 후에 보강을 실시하며, 이를 보강 실시 전에 학습자에게 공지 또는 통지합니다.<br/>
              ③ 학습자는 제2항에 의한 보강을 실시하기 전에 수강계약을 해지할 수 있으며, 민간자격관리자는 학습자가 계약을 해지한 때 해지의 의사표시를 받은 날로부터 3영업일 이내에 제37조 제2항 제2호에 해당하는 금액을 환급합니다.<br/>
            </div>

            <FormControlLabel className="Checkbox"
              control={<Checkbox color="primary" />}
              name="isService"
              label="본인은 서비스 이용약관을 확인하였고 약관에 동의합니다."
              onChange={onChange}
            />

            <div className="h3">개인정보 수집 및 이용안내</div>
            <div className="terms">
              ■ 개인정보의 수집 및 이용목적<br/>
              회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br/>
              <br/>
              ο 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산<br/>
              구매 및 요금 결제 , 물품배송 또는 청구지 등 발송 , 금융거래 본인 인증 및 금융 서비스<br/>
              ο 회원 관리<br/>
              회원제 서비스 이용에 따른 본인확인 , 불량회원의 부정 이용 방지와 비인가 사용 방지 , 가입 의사 확인 , 연령확인 , 불만처리 등 민원처리 , 고지사항 전달<br/>
              <br/>
              ■ 수집하는 개인정보 항목<br/>
              회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br/>
              ο 수집항목 : 이름 , 생년월일 , 로그인ID , 비밀번호 , 자택 전화번호 , 자택 주소 , 휴대전화번호 , 이메일 , 주민등록번호 , 신용카드 정보 , 은행계좌 정보 , 접속 로그 , 쿠키 , 결제기록<br/>
              ο 개인정보 수집방법 : 홈페이지(회원가입,게시판,조세전문서점 판매,세무상담 등)<br/>
              <br/>
              ■ 개인정보의 보유 및 이용기간<br/>
              회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다.
            </div>

            <div className="guide-txt">
              ※ 자격기본법에 의거한 주민번호 수집 : <span className="cred">자격기본법 시행령 제32조에 의거하여 원서접수, 합격자발표, 자격증발급에 한하여 주민번호를 수집하며, 합격자 발표 후, 합격자를 제외한 응시자들의 주민번호는 자동 파기 됩니다.</span> <a href="https://www.law.go.kr/LSW/lsSc.do?y=0&x=0&nwYn=1&p1=&menuId=0&query=%EC%9E%90%EA%B2%A9%EA%B8%B0%EB%B3%B8%EB%B2%95&subMenu=1&section=#liBgcolor0" target="_blank" rel="noreferrer">[자격기본법 바로가기]</a><br/>
              ※ 본인확인 및 동의 : 개인정보 보호를 위해 본인확인을 실시하고 있습니다.
            </div>
            <FormControlLabel className="Checkbox"
              control={<Checkbox color="primary" />}
              name="isPrivacy"
              label="본인은 개인정보 수집 및 이용에 대한 안내를 확인하였고 이에 동의합니다."
              onChange={onChange}
            />

            <div className="h3">본인인증</div>
            <div className="terms terms1">
              <div>
                개인정보 보호를 위해 본인확인을 실시하고 있습니다.<br/>
                입력하시는 본인정보는 본인확인의 용도로만 사용되며, 본인확인기관의 인증을 통해 진행됩니다. <br/>
                이에 동의하실 경우 본인확인을 진행해주세요.
              </div>

              <Button
                variant="contained"
                className={isAgree.isService && isAgree.isPrivacy ? "HBtn bluebg" : "HBtn"}
                disabled={isAgree.isService && isAgree.isPrivacy ? false : true}
                onClick={submit}
                fullWidth>
                본인인증
              </Button>
            </div>

          </div>
        </div>
      </div>
   </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default JoinStep1;