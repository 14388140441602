/*****************************************************************************************
 * 설명 : 다음 주소 컴포넌트
 * 작성자 : 김종현
 * 작성일 : 2022-01-07
*****************************************************************************************/
import DaumPostCode from 'react-daum-postcode';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const DaumPost = (props) => {
    const handleComplete = (data) => {
      let gugun = data.sigungu.split(' ');
      let fullAddress = data.address;
      let zoneCode = data.zonecode;
      let sido= data.sido;
      let sigungu = gugun[0];
      let bname = data.bname;
      props.close(fullAddress, zoneCode, sido, sigungu, bname);
    }

    return (
      <div className="center-add modal-wrap" style={{ width: '800px' }}>
        <div className="modal-header">
          <div className="modal-title">주소검색</div>
          <Button className="close-btn" onClick={props.close}>
            <CloseIcon style={{ color: '#fff', fontSize: '28px' }}></CloseIcon>
          </Button>
        </div>
        <DaumPostCode onComplete={handleComplete} className="post-code" style={{ height: '450px' }}/>
      </div>
    );
}
export default DaumPost;