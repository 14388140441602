/*****************************************************************************************
 * 설명 : Footer
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { NavLink } from 'react-router-dom';

import footerLogo from 'assets/images/home/footer/logo_footer.png';

import './footer.scss';
import TopButton from './topButton';


/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Footer = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <div className="footer-home">
      <div className="footer1">
        <ul className="flink">
          <li><a href="https://www.motie.go.kr/" target="_blank" rel="noreferrer">산업통상자원부</a></li>
          <li><a href="http://www.ref.or.kr/main" target="_blank" rel="noreferrer">한국냉동공조산업협회</a></li>
          <li><a href="http://www.harfko.com/#/" target="_blank" rel="noreferrer">HARFKO2024</a></li>
          <li><a href="https://www.rims.or.kr/main.do" target="_blank" rel="noreferrer">냉매정보관리시스템</a></li>
        </ul>
      </div>
      <div className="footer2">
        <ul className="fmenu clearfix">
          <li><a href="http://www.ref.or.kr/contents?menu_seq=407" target="_blank" rel="noreferrer" className="fmenu-list">개인정보처리방침</a></li>
          <li><a href="https://www.ref.or.kr/contents?menu_seq=118" target="_blank" rel="noreferrer" className="fmenu-list">오시는 길</a></li>
          <li><a href="http://www.ref.or.kr/contents?menu_seq=411" target="_blank" rel="noreferrer" className="fmenu-list">이메일주소 무단수집거부</a></li>
        </ul>
      </div>
      <div className="footer3 inner clearfix">
        <NavLink to="/" className="footer-logo">
          <img src={footerLogo} alt="한국냉동공조산업협회 자격시험센터" />
        </NavLink>
        <div className="addr">
          <p>대표 강성희<span className="line"></span>사업자등록번호 116-82-01412</p>
          <p>06170 서울시 강남구 봉은사로 622(삼성동) 공조회관<br className="mobile-only" /><span className="line pc-only"></span>TEL  02-2193-4300<span className="line"></span>FAX 02-369-7515, 785-1195</p>
          <p>Copyright ⓒ 2016 KRAIA Corp. All Rights Reserved.</p>
        </div>
      </div>

      <TopButton></TopButton>
    </div>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Footer;