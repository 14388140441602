/*****************************************************************************************
 * 설명 : 아이디/비밀번호 찾기
 * URI : /findIdPw
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";

import { baseURL } from 'config/config';

import alertMsg from 'components/message';

import Restful from 'service/restful';

import Layout from 'pages/homepage/layout/layout';

import './findIdPw.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FindIdPw = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const history = useNavigate();
  const popup = useRef(null);
  const isCheck = useRef(0);
  const formikValues = useRef({});

  const formik = useFormik({
    initialValues: {
      userName: '',
    },
    validationSchema: Yup.object().shape({
      userName: Yup.string().min(2, '최소2자').max(20, "20자리").required('필수'),
    }),
    onSubmit: (values) => {
      isCheck.current = 0;
      formikValues.current = {...values};
      popup.current = window.open( baseURL + '/api/checkplus/checkplus_main.php?type=1', 'Parent_window', 'width=500,height=550,scrollbar=yes' );
    }
  });

  const onChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value)
  }

  const formik1 = useFormik({
    initialValues: {
      id: '',
      userName: '',
    },
    validationSchema: Yup.object().shape({
      id: Yup.string().min(4, '최소4자').max(20, "20자리").required('필수'),
      userName: Yup.string().min(2, '최소2자').max(20, "20자리").required('필수'),
    }),
    onSubmit: (values) => {
      isCheck.current = 1;
      formikValues.current = {...values};
      popup.current = window.open( baseURL + '/api/checkplus/checkplus_main.php?type=2', 'Parent_window', 'width=500,height=550,scrollbar=yes' );
    }
  });

  const onChange1 = (e) => {
    formik1.setFieldValue(e.target.name, e.target.value)
  }

  /***************************************************************************************
   * 설명 : 메시지 처리
  ***************************************************************************************/
  const findId = (values) => {
    let params = {
      program: 'home',
      service: 'member',
      version: '1.0',
      action: 'setFindId',
      userName: formikValues.current.userName,
      dkey: values.dkey
    }

    getApi("post", params).then( response => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", 3000);
        history('/findId/result', {state: response.data.data[0]});

      } else {
        alertMsg(response.data.message, "E", 3000);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 비밀번호 찾기
  ***************************************************************************************/
  const findPW = (values) => {
    let params = {
      program: 'home',
      service: 'member',
      version: '1.0',
      action: 'setFindPw',
      id: formikValues.current.id,
      userName: formikValues.current.userName,
      dkey: values.dkey
    }

    getApi("post", params).then( response => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", 3000);
        history('/findPw/result', response.data.data[0]);
      } else {
        alertMsg(response.data.message, "E", 3000);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 메시지 처리
  ***************************************************************************************/
  const receiveMessage = (event) => {
    if( event.origin === window.location.origin ) {
      if( (event.data?.data ?? '') !== '' ) {
        const jsonData = JSON.parse(event.data.data);

        window.removeEventListener("message", receiveMessage);

        if( isCheck.current === 0 ) {
          findId({
            dkey: jsonData.conninfo
          });
        } else {
          findPW({
            dkey: jsonData.conninfo
          });
        }

      }
    }
  }

  /***************************************************************************************
   * 설명 : 윈도우 메시지 시작
  ***************************************************************************************/
  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);

    return () => {
      window.removeEventListener("message", receiveMessage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="findIdPw inner">
        <div className="page-title">아이디/비밀번호 찾기</div>

        <div className="clearfix">
          <div className="find-div">
            <form onSubmit={formik.handleSubmit}>
              <div className="find-title">아이디 찾기</div>
                <TextField
                  variant="outlined"
                  className="input"
                  placeholder="이름"
                  autoFocus
                  fullWidth
                  name='userName'
                  value={formik.values.userName}
                  helperText={formik.touched['userName'] && formik.errors["userName"]}
                  error={formik.touched['userName'] && Boolean(formik.errors['userName'])}
                  onChange={onChange}
                />
              <Button type='submit' variant="contained" className="HBtn blackbg" fullWidth>아이디 찾기</Button>
            </form>
          </div>
          <div className="find-div">
            <form onSubmit={formik1.handleSubmit}>
              <div className="find-title">비밀번호 찾기</div>
                <TextField
                  variant="outlined"
                  className="input"
                  placeholder="아이디"
                  autoFocus
                  fullWidth
                  name='id'
                  value={formik1.values.id}
                  helperText={formik1.touched['id'] && formik1.errors["id"]}
                  error={formik1.touched['id'] && Boolean(formik1.errors['id'])}
                  onChange={onChange1}
                />
                <TextField
                  variant="outlined"
                  className="input"
                  placeholder="이름"
                  fullWidth
                  name='userName'
                  value={formik1.values.userName}
                  helperText={formik1.touched['userName'] && formik1.errors["userName"]}
                  error={formik1.touched['userName'] && Boolean(formik1.errors['userName'])}
                  onChange={onChange1}
                />
                <Button type='submit' variant="contained" className="HBtn blackbg" fullWidth>비밀번호 찾기</Button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FindIdPw;