/*****************************************************************************************
 * 설명 : 메인 - home
 * URI : /
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
// import { useHistory } from "react-router";

import Layout from 'pages/homepage/layout/layout';

import Restful from 'service/restful';
import './main.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Main = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  // const history = useHistory();

  const [getApi] = Restful();
  const navigate = useNavigate();

  const [notice, setNotice] = useState([{
    subject: '',
    createDate: ''
  }])

  const getBoardList = () => {
    let params = {
      program: 'home',
      service: 'board',
      version: '1.0',
      action: 'getBoardNotice',
      boardId: 'notice',
      searchText: '',
      searchType: '',
      pageNo: 1,
      pageRow: 3
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result ) {
        setNotice(response.data.data);

        if( response.data.data.length === 0 ) {
          setNotice([]);
        }

      } else {
        setNotice([])
      }
    });
  }

  const setBoardView = (item) => {
    let params = {
      program: 'home',
      service: 'board',
      version: '1.0',
      action: 'setBoardView',
      boardId: item.boardId,
      seq: item.seq
    }

    getApi("post", params).then( response => {
      if( response !== undefined && response.data.result ) {
        navigate('/board/boardView?boardId='+ item.boardId, {state: item});
        window.scrollTo(0,0);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 게시판 정보 가져오기
  ***************************************************************************************/
  useEffect(() => {
    getBoardList();

    // eslint-disable-next-line
  }, [])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="main-home">
        <section className="quick-menu1">
          <ul className="inner clearfix">
            <li>
              <NavLink to="/exam/application/form" className="link-item">
                <p className="title">원서접수</p>
                <p className="txt">냉동공조산업발전에 동참할<br/>새로운 기회를 드립니다</p>
                <Button className="link-btn">바로가기</Button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/education/form" className="link-item">
                <p className="title">보수교육접수</p>
                <p className="txt">보수교육을 통해<br/>자격을 유지하세요</p>
                <Button className="link-btn">바로가기</Button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/exam/passList" className="link-item">
                <p className="title">합격자발표</p>
                <p className="txt">시스템에어컨설계시공관리사<br/>냉매취급관리사</p>
                <Button className="link-btn">바로가기</Button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/exam/certification/form" className="link-item">
                <p className="title">자격증발급</p>
                <p className="txt">자격증 발급<br/>발급신청 확인</p>
                <Button className="link-btn">바로가기</Button>
              </NavLink>
            </li>
          </ul>
        </section>

        <section className="quick-menu2">
          <div className="inner clearfix">
            {/* <Button className="link-btn"  onClick={() => {history.push('/education/list')}}><span className="btn-title">보수교육접수확인</span></Button>
            <Button className="link-btn"  onClick={() => {history.push('/shop/Orderlist')}}><span className="btn-title">교재 배송조회</span></Button>
            <Button className="link-btn"  onClick={() => {history.push('/mypage/validation')}}><span className="btn-title">유효인증확인</span></Button> */}
          </div>
        </section>

        <section className="quick-menu3">
          <NavLink to="/shop/list" className="inner link-btn">
            <div>
              <p className="title">교재구매</p>
              <p className="txt">시스템에어컨설계시공관리사 자격검정대비 / 출제예상문제 교재,<br/>냉매취급관리사 자격검정대비 교재를 구매하실 수있습니다.</p>
            </div>
          </NavLink>
        </section>

        <section className="notice">
          <div className="inner clearfix">
            <div className="notice-fl">
              <div className="notice-title">공지사항</div>
              <div className="guide-txt">자격검정시험 일정과<br/>보수교육일정 등을 확인하세요.</div>
              <NavLink to="/board/list?boardId=notice" className="more">더보기</NavLink>
            </div>

            <div className="notice-list">
              <ul>
              {
                notice.map((item, i) => {
                  return (
                    <li className="cp" key={i} onClick={() => setBoardView(item)}>
                      <p className="txt">{item.subject}</p>
                      <p className="date">{item.createDate}</p>
                    </li>
                  )
                })
              }
              </ul>
            </div>
          </div>
        </section>

        <section className="quick-menu4">
          <ul className="inner clearfix">
            <li>
              <NavLink to="/contents?seq=42" className="link-item">
                <p className="title">자격정보</p>
                <p className="txt">자격에 관한 기본적인 사항을<br className="pc-only" />알려드립니다.</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/information/info2" className="link-item">
                <p className="title">검정정보</p>
                <p className="txt">응시자격 및 검정과목,<br className="pc-only" />검정절차 등을 알려드립니다.</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/information/info3" className="link-item">
                <p className="title">민간자격/국가공인</p>
                <p className="txt">민간자격제도와 국가공인제도,<br className="pc-only" />기준 및 절차를<br className="mobile-only" /> 알려드립니다.</p>
              </NavLink>
            </li>
          </ul>
        </section>
      </div>
   </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Main;