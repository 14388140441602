/*****************************************************************************************
 * 설명 : 로그인 header
 * 작성자 : 
 * 작성일 : 
*****************************************************************************************/
import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from 'assets/images/admin/logo.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const HeaderLogin = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <div className="header-admin">
      <NavLink to="/" className="logo">
        <img src={logo} alt="logo"/>
      </NavLink>
            
      <div className="util-area">
        <div className="top-icon">
          <a href="https://gw.o2oz.net/customer/login" target="_blank" rel="noreferrer" title="A/S문의">
            <span className="ico-firstas right-icon"></span>
          </a>
        </div>
        <div className="top-icon">
          <a href="http://helppc.net/o2oz2016" target="_blank" rel="noreferrer" title="원격지원">
            <span className="ico-firstremote right-icon"></span>
          </a>
        </div>
        <div className="top-icon">
          <a href="http://www.ref.or.kr/" target="_blank" rel="noreferrer" title="홈페이지">
            <span className="material-icons right-icon">home</span>
          </a>
        </div>
        <div className="top-icon">
          <a href="http://hrms.ref.or.kr" target="_blank" rel="noreferrer" title="전자결재시스템">
            <span className="ico-firstest right-icon"></span>
          </a>
        </div>
      </div>
    </div>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default HeaderLogin;