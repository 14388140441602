/*****************************************************************************************
 * 설명 : 회원가입 > 정보입력
 * URI : /join/step2
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { Button, Modal, TextField } from '@mui/material';
import { useFormik } from "formik";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";

import { InputEx, ToggleButtonGroupEx } from 'components/inputEx';

import DaumPost from 'components/DaumAddress';
import Layout from 'pages/homepage/layout/layout';
import './join.scss';

import alertMsg from "components/message";

import { MESSAGE_DELAY } from 'config/config';
import Restful from "service/restful";


/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const JoinStep2 = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const location = useLocation();
  const history = useNavigate();

  const [openModal1, setOpenModal1] = useState(false);
  const [hpauth, setHpauth] = useState({});

  const handleOpenModal1 = () => {
    setOpenModal1(true);
  };

  const handleCloseModal1 = (fullAddress, zoneCode, sido, sigungu) => {
    if ( typeof(fullAddress) === 'object' ) {
      formik.setFieldValue('zipCode', '');
      formik.setFieldValue('address', '');

    } else {
      formik.setFieldValue('zipCode', zoneCode);
      formik.setFieldValue('address', fullAddress);
    }
    setOpenModal1(false);
  };


  const formik = useFormik({
    initialValues: {
      id: '',
      userName: '',
      userNameEn: '',
      pw: '',
      pwConfirm: '',
      gender: 'M',
      birthDate: '',
      hp: '',
      tel: '',
      email: '',
      isChecked: false,
      zipCode: '',
      address: '',
      addressDetail: '',
      dkey: '',
      requestNumber: '',
      authType: ''
    },
    validationSchema: Yup.object().shape({
      id: Yup.string().min(4, '최소4자').max(20, "20자리").required('필수'),
      userName: Yup.string().min(2, '최소2자').max(20, "20자리").required('필수'),
      birthDate: Yup.string().min(2, '최소2자').max(20, "20자리").required('필수'),
      pw: Yup.string().min(8, '최소8자').max(15, "15자리").required('필수')
      .test(
        'password change check',
        '영문대/소문자,숫자,특수문자 미포함',
        function( str ) {
          const tmp = String(str);
          if( typeof tmp.match !== "function" ) return true;

          return tmp.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&()\-_+=~./])[A-Za-z\d$@$!%*#?&()-_+=~./]{8,16}$/) ? true : false;
        }
      )
      .test(
        'password change check',
        '영문대/소문자,숫자,특수문자',
        function( str ) {
          const tmp = String(str);
          if( typeof tmp.match !== "function" ) return true;

          return tmp.match(/^[A-Za-z0-9$^@$!%*#?&()\-_+=~./]+$/);
        }
      ),
      // pwConfirm: Yup.string().min(8, '최소8자').max(16, "16자리").required('필수').when("pw", {
      //   is: val => (val && val.length > 0 ? true : false),
      //   then: Yup.string().oneOf(
      //     [Yup.ref("pw")],
      //     "불일치"
      //   )
      // }),
      hp: Yup.string().min(9, '최소9자').max(13, "13자리").required('필수'),
      tel: Yup.string().min(9, '최소9자').max(14, "14자리"),
      email: Yup.string().max(30, "30자리").required('필수')
      .test(
        'email check',
        '이메일 형식 체크',
        function( str ) {
          const tmp = String(str);
          if( typeof tmp.match !== 'function' ) return true;

          return tmp.match( /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
        }
      )
    }),
    onSubmit: (values) => {
      if( values.isChecked === false ) {
        alertMsg('아이디 중복확인을 하시기 바랍니다.', "W", 3000);

      } else {
        let params = {
          program: 'home',
          service: 'member',
          version: '1.0',
          action: 'setMemberJoin',
          id: values.id,
          userName: values.userName,
          userNameEn: values.userNameEn,
          pw: values.pw,
          pwConfirm: values.pwConfirm,
          hp: values.hp,
          tel: values.tel,
          email: values.email,
          birthDate: values.birthDate,
          zipCode: values.zipCode,
          address: values.address,
          addressDetail: values.addressDetail,
          authType: hpauth?.authtype,
          gender: parseInt(hpauth?.gender) === 1 ? 'M' : 'F',
          dkey: hpauth?.conninfo,
          requestNumber: hpauth?.requestnumber
        }

        getApi("post", params).then( response => {
          if( response !== undefined && response.data.result ) {
            alertMsg(response.data.message, "S", 3000);
            history('/join/complete');

          } else {
            alertMsg(response.data.message, "E", 3000);
          }
        });
      }
    }
  });

  /***************************************************************************************
   * 설명 : 중복회원 아이디 체크
  ***************************************************************************************/
  const checkIdDuplicate = () => {
    let params = {
      program: 'home',
      service: 'member',
      version: '1.0',
      action: 'checkIdDuplicate',
      id: formik.values.id
    }

    if( formik.values.id === '' ) {
      alertMsg('아이디를 입력해주세요.', "W", 3000);
    } else {
      formik.setFieldValue('isChecked', true);

      getApi("get", params).then( response => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", 3000);

        } else {
          formik.setFieldValue('isChecked', false);
          alertMsg(response.data.message, "E", 3000);
        }
      });
    }
  }

  const onBlurChecked = () => {
    formik.setFieldValue('isChecked', false);
  }

  const onChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value)
  }

  /***************************************************************************************
   * 설명 : 휴대폰 인증에서 넘어온 값을 체크
  ***************************************************************************************/
  useEffect(() => {
    if( (hpauth.authtype ?? '') !== '' && formik && formik.values.authType === '' ) {
      formik.setFieldValue('authType', hpauth.authtype);
      formik.setFieldValue('birthDate', moment(hpauth.birthdate).format('YYYY-MM-DD'));
      formik.setFieldValue('gender', parseInt(hpauth.gender) === 1 ? 'M' : 'F');
      formik.setFieldValue('dkey', hpauth.conninfo);
      formik.setFieldValue('userName', hpauth.name);
      formik.setFieldValue('requestNumber', hpauth.requestnumber);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik, hpauth]);

  /***************************************************************************************
   * 설명 : 휴대폰 인증에서 넘어온 값을 체크
  ***************************************************************************************/
  useEffect(() => {
    const tmp = location.state;

    if( (tmp.authtype ?? '') === '' ) {
      alertMsg("본인인증 작업을 진행해 주시기 바랍니다.", "W", MESSAGE_DELAY);
      // history("/join/step1");

    } else {
      setHpauth(tmp);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="join-home">
        <form onSubmit={formik.handleSubmit}>
          <div className="inner">
            <div className="sub-header clearfix">
              <div className="page-title">회원가입</div>
              <ul className="path clearfix">
                <li>약관동의</li>
                <li className="active">정보입력</li>
                <li>가입완료</li>
              </ul>
            </div>

            <div className="join-step2">
              <div className="step2-wrap">
                <table>
                  <colgroup>
                    <col width="145px" />
                    <col width="500px" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>아이디<span className="cred">*</span></th>
                      <td className='addr'>
                        <TextField
                          variant="outlined"
                          className="input zip"
                          autoFocus
                          name='id'
                          value={formik.values.id}
                          helperText={formik.touched['id'] && formik.errors["id"]}
                          error={formik.touched['id'] && Boolean(formik.errors['id'])}
                          onChange={onChange}
                          onBlur={onBlurChecked}
                        />
                        <Button className="HBtn" onClick={checkIdDuplicate}>중복체크</Button>
                      </td>
                    </tr>
                    <tr>
                      <th>비밀번호<span className="cred">*</span></th>
                      <td>
                        <TextField
                          type='password'
                          variant="outlined"
                          className="input"
                          placeholder="영문/숫자/특수문자 조합 8~15자"
                          fullWidth
                          name='pw'
                          value={formik.values.pw}
                          helperText={formik.touched['pw'] && formik.errors["pw"]}
                          error={formik.touched['pw'] && Boolean(formik.errors['pw'])}
                          onChange={onChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>비밀번호 확인<span className="cred">*</span></th>
                      <td>
                        <TextField
                          type='password'
                          variant="outlined"
                          className="input"
                          fullWidth
                          name='pwConfirm'
                          value={formik.values.pwConfirm}
                          helperText={formik.touched['pwConfirm'] && formik.errors["pwConfirm"]}
                          error={formik.touched['pwConfirm'] && Boolean(formik.errors['pwConfirm'])}
                          onChange={onChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>회원이름<span className="cred">*</span></th>
                      <td>
                        <InputEx
                          name="userName"
                          formik={formik}
                          fullWidth={true}
                          className="input"
                          disabled={true}
                        />
                        {/*
                        <TextField
                          variant="outlined"
                          className="input"
                          fullWidth
                          name='userName'
                          value={formik.values.userName}
                          helperText={formik.touched['userName'] && formik.errors["userName"]}
                          error={formik.touched['userName'] && Boolean(formik.errors['userName'])}
                          onChange={onChange}
                        />
                        */}
                      </td>
                    </tr>
                    <tr>
                      <th>회원이름(영문)</th>
                      <td>
                        <TextField
                          variant="outlined"
                          className="input"
                          fullWidth
                          name='userNameEn'
                          value={formik.values.userNameEn}
                          helperText={formik.touched['userNameEn'] && formik.errors["userNameEn"]}
                          error={formik.touched['userNameEn'] && Boolean(formik.errors['userNameEn'])}
                          onChange={onChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>생년월일<span className="cred">*</span></th>
                      <td>
                        <TextField
                          variant="outlined"
                          type='date'
                          className="input"
                          fullWidth
                          name='birthDate'
                          value={formik.values.birthDate}
                          helperText={formik.touched['birthDate'] && formik.errors["birthDate"]}
                          error={formik.touched['birthDate'] && Boolean(formik.errors['birthDate'])}
                          onChange={onChange}
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>성별<span className="cred">*</span></th>
                      <td>
                        {/*
                        <TextField
                          variant="outlined"
                          className="input"
                          fullWidth
                          name='gender'
                          value={formik.values.gender}
                          helperText={formik.touched['gender'] && formik.errors["gender"]}
                          error={formik.touched['gender'] && Boolean(formik.errors['gender'])}
                          onChange={onChange}
                        />
                      */}
                        <ToggleButtonGroupEx
                          name="gender"
                          exclusive={true}
                          formik={formik}
                          className="input"
                          disabled={true}
                          data={[
                            {label: '남자', value: 'M'},
                            {label: '여자', value: 'F'},
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>이메일<span className="cred">*</span></th>
                      <td>
                        <TextField
                          variant="outlined"
                          className="input"
                          fullWidth
                          name='email'
                          value={formik.values.email}
                          helperText={formik.touched['email'] && formik.errors["email"]}
                          error={formik.touched['email'] && Boolean(formik.errors['email'])}
                          onChange={onChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>휴대전화<span className="cred">*</span></th>
                      <td>
                        <TextField
                          variant="outlined"
                          className="input"
                          fullWidth
                          name='hp'
                          value={formik.values.hp}
                          helperText={formik.touched['hp'] && formik.errors["hp"]}
                          error={formik.touched['hp'] && Boolean(formik.errors['hp'])}
                          onChange={onChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>자택전화</th>
                      <td>
                        <TextField
                          variant="outlined"
                          className="input"
                          fullWidth
                          name='tel'
                          value={formik.values.tel}
                          helperText={formik.touched['tel'] && formik.errors["tel"]}
                          error={formik.touched['tel'] && Boolean(formik.errors['tel'])}
                          onChange={onChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>자택주소<span className="cred">*</span></th>
                      <td className="addr">
                        <TextField
                          variant="outlined"
                          className="input zip disabled"
                          disabled
                          placeholder="우편번호"
                          name='zipCode'
                          value={formik.values.zipCode}
                          helperText={formik.touched['zipCode'] && formik.errors["zipCode"]}
                          error={formik.touched['zipCode'] && Boolean(formik.errors['zipCode'])}
                          onChange={onChange}
                        />
                        <Button className="HBtn" onClick={handleOpenModal1}>우편번호</Button>
                        <TextField
                          variant="outlined"
                          className="input addr1 disabled"
                          disabled
                          fullWidth
                          placeholder="기본주소"
                          name='address'
                          value={formik.values.address}
                          helperText={formik.touched['address'] && formik.errors["address"]}
                          error={formik.touched['address'] && Boolean(formik.errors['address'])}
                          onChange={onChange}
                        />
                        <TextField
                          variant="outlined"
                          className="input"
                          fullWidth
                          placeholder="나머지 주소"
                          name='addressDetail'
                          value={formik.values.addressDetail}
                          helperText={formik.touched['addressDetail'] && formik.errors["addressDetail"]}
                          error={formik.touched['addressDetail'] && Boolean(formik.errors['addressDetail'])}
                          onChange={onChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Button type='submit' variant="contained" className="HBtn join-btn blackbg">회원가입</Button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal
        open={openModal1}
        onClose={handleCloseModal1}
      >
        <div className="modal">
          <DaumPost close={handleCloseModal1} />
        </div>
      </Modal>
   </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default JoinStep2;