/*****************************************************************************************
 * 설명 : 전역 앱 설정
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import { MESSAGE_DELAY } from "config/config";
import IndicatorContext from 'config/indicator';
import GlobalMenu, { initMenuList } from 'config/menu';
import { setIsLogout, setUserInfo } from 'reducers/userReducer';

import SiteRouter from 'pages/siteRouter';

import Indicator from 'components/indicator';
import alertMsg from 'components/message';

import { Restful } from 'service/restful';

import { setMenuCurrent, setMenuInfo, setMenuOrigin } from 'reducers/menuListReducer';
import { setSiteInfo } from 'reducers/siteInfoReducer';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'react-toastify/dist/ReactToastify.css';

import 'assets/css/admin/layout.scss';
import 'assets/css/common.scss';
import 'assets/css/company/layout.scss';
import 'assets/css/font.css';
import 'assets/css/layout.scss';
import 'assets/css/material.scss';
import 'assets/css/modal.scss';
import 'assets/css/print.scss';
import 'assets/css/toastify.css';

import 'assets/css/home/layout.scss';

import PopupLayout from 'pages/homepage/layout/popupLayout';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const App = (props) => {

  /***************************************************************************************
   * 설명 : 메뉴 리스트
  ***************************************************************************************/
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();

  const menuOriginList = useSelector(state => state?.menuList?.list);
  const isLogout = useSelector(state => state?.userInfo.isLogout);
  const user = useSelector(state => state?.userInfo?.user);

  const [isMenuLoading, setIsMenuLoading] = useState(false);
  const [menuList, setMenuList] = useState(initMenuList);
  const [menuIndex, setMenuIndex] = useState(-1);
  const [menuReduce, setMenuReduce] = useState('');

  const [isIndicator, setIndicator] = useState(false);
  const indicator = { isIndicator, setIndicator };

  const [getApi] = Restful();

  const valueMenu = {menuList, setMenuList, menuIndex, setMenuIndex, menuReduce, setMenuReduce };

  const uri = window.location.pathname;
  const uriQuery = window.location.pathname + window.location.search;

  /***************************************************************************************
   * 설명 : 트리 메뉴 구성 시 부모 트리 찾기
  ***************************************************************************************/
  const findMenuTree = (menuList, pageCode) => {
    var result;
    menuList.some(o => result = o.pageCode === pageCode ? o : findMenuTree(o.children || [], pageCode));
    return result;
  }

  /***************************************************************************************
   * 설명 : 트리 메뉴 구성
  ***************************************************************************************/
  const setMenuTree = (menu, index) => {
    let menuList = [];
    let tmp1 = null;

    for(let i = index; i < menu.length; i++ ) {

      let isActive = false;
      if( (menu[i].queryString ?? '') !== '' ) {
        isActive = uriQuery.indexOf(menu[i].linkUrl + menu[i].queryString) > -1 ? true : false;
      } else {
        isActive = menu[i].linkUrl === uri ? true : false;
      }

      if( isActive ) {
        let tmp = menu.filter((item) => item.pageCode === menu[i].pageCode.substr(0, menu[i].pageCode.length - 3));
        tmp1 = {
          ...menu[i],
          parentType: 1,
          parent: tmp.length > 0 ? tmp[0] : {}
        }

        dispatch(setMenuCurrent(tmp1));
      }

      if( menu[i].pageCode.length === 3 ) {
        menuList.push({
          ...menu[i],
          isActive: isActive,
          children: [],
          parent: null
        });

      } else {
        let node = findMenuTree(menuList, menu[i].pageCode.substr(0, menu[i].pageCode.length - 3));

        let isActive = menu[i].linkUrl === uri ? true : false;

        if( (node ?? '') !== '' ) {
          node.children.push({
            ...menu[i],
            isActive: isActive,
            children: [],
            parent: {
              seq: node.seq,
              pageName: node.pageName
            }
          });
        }
      }
    }

    return (tmp1 === null) ? null : menuList;
  }

  /***************************************************************************************
   * 설명 : 서버에서 사이트(메뉴) 정보 가져오기
  ***************************************************************************************/
  const getSiteInfo = () => {
    // 첫번째 uri 보냄 사이트 구분자
    let paramsUri = uri.split(/\//gi);

    if( paramsUri[1].indexOf('admin') !== 0 && paramsUri[1].indexOf('user') !== 0 && paramsUri[1].indexOf('company') !== 0 )
      paramsUri = '/';
    else
      paramsUri = paramsUri[1];

    let params = {
      program: 'api',
      service: 'cms',
      action: 'getSiteInfo',
      version: '1.0',
      domain: window.location.hostname,
      uri: paramsUri
    }

    // 로그인 정보 및 사이트 정보 가져오기
    getApi("get", params).then( response => {
      if( response.data.result ) {

        // 로그인 정보 저장
        if( response.data.loginCheck && response.data.member.length > 0 ) {
          dispatch(setUserInfo(response.data.member[0]));
        }

        // 사이트 정보 저장
        if( response.data.siteInfo && response.data.siteInfo.length > 0 )
          dispatch(setSiteInfo(response.data.siteInfo[0]));

        // 메뉴 리스트 정보 저장
        if( response.data.data && response.data.data.length > 0 ) {
          // 메뉴 트리 구성
          const tmp = setMenuTree(response.data.data, 0);
          setMenuList(tmp);

          // 트리 메뉴 저장
          dispatch(setMenuInfo(tmp));

          // 리스트 메뉴 저장
          dispatch(setMenuOrigin(response.data.data));
        } else {
          if( uri.indexOf('/admin') > -1 ) {
            if( uri !== '/admin/login' ) {
              alertMsg("관리자 로그인이 필요합니다.", "E", MESSAGE_DELAY);
              dispatch(setIsLogout());
              history( '/admin/login' );
            }

          } else if( uri.indexOf('/company') > -1 ) {
            if( uri !== '/company' ) {
              alertMsg("업체 로그인이 필요합니다.", "E", MESSAGE_DELAY);
              dispatch(setIsLogout());
              history( '/company' );
            }
          }
        }

        // 페이지 접근 권한 체크 필요
      }
    })
  }

  /***************************************************************************************
   * 설명 : 현재 페이지 정보 저장
  ***************************************************************************************/
  useEffect(() => {
    if( isMenuLoading && menuOriginList && menuOriginList.length > 0  ) {
      menuOriginList.forEach((menu) => {
        // 현재 페이지 정보 저장
        if( menu.isRouter !== 0 && menu.isRouter !== '0' ) {
          if( menu.linkUrl === window.location.pathname ) {
            let tmp = menuOriginList.filter((item) => item.pageCode === menu.pageCode.substr(0, menu.pageCode.length - 3));
            let tmp1 = {
              ...menu,
              parentType: 0,
              parent: ( tmp.length > 0 ) ? tmp : {}
            };

            dispatch(setMenuCurrent(tmp1));

            if( parseInt(tmp1.authView) === 0 ) {
              history('/');
            }

            if( parseInt(tmp1.isLogin) === 1 ) {
              if( (user.mem_no ?? '') === '') {
                history('/login');
              }
            }
          }
        }
      });
    }

    getSiteInfo();

    // eslint-disable-next-line
  }, [isMenuLoading, location, window.location.search]);

  /***************************************************************************************
   * 설명 : 메뉴 데이터가 로딩 되었는지 체크
  ***************************************************************************************/
  useEffect(() => {
    if( menuOriginList && menuOriginList.length > 0  ) {
      setIsMenuLoading(true);
    }
  }, [menuOriginList])

  /***************************************************************************************
   * 설명 : 로그아웃 체크
  ***************************************************************************************/
  useEffect(() => {
    if( isLogout === true ) {
      //dispatch(setIsLogout());

      // 세션 로그아웃일 경우
      let uri = document.location.href;
      if( uri.indexOf('/admin') > -1 ) {
        if( uri !== '/admin/login' ) {
          alertMsg("관리자 로그인이 필요합니다.", "E", MESSAGE_DELAY);
          history( '/admin/login' );
        }

      } else {
        // if( uri !== '/login' ) {
        //   alertMsg("회원 로그인이 필요합니다.", "E", MESSAGE_DELAY);
        //   history( '/login' );
        // }
      }
    }

    // eslint-disable-next-line
  }, [isLogout]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
   return (
    <>
      <ToastContainer
        limit={10}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable
        pauseOnHover
      />
      <Indicator checked={isIndicator} />

      <IndicatorContext.Provider value={indicator}>
        <GlobalMenu.Provider value={valueMenu}>
          <SiteRouter
            history={history}
          ></SiteRouter>
          <PopupLayout />
        </GlobalMenu.Provider>
      </IndicatorContext.Provider>
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default App;