/*****************************************************************************************
 * 설명 : Header - home
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { logout } from 'reducers/userReducer';

import logo from 'assets/images/home/header/logo.png';

import Restful from "service/restful";

import './header.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Header = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const dispatch = useDispatch();
  const [getApi] = Restful();
  const history = useNavigate();

  const [menuIndex, setMenuIndex] = useState(-1);

  const menuList = useSelector(state => state?.menuList);
  const currentMenu = useSelector(state => state?.menuList?.current);
  const user = useSelector(state => state?.userInfo?.user);

  const [menuOpen, setMenuOpen] = React.useState('');

  //const user = window.sessionStorage.getItem('token');

  /***************************************************************************************
   * 설명 : 로그아웃 처리
  ***************************************************************************************/
  const setLogout = () => {
    let params = {
      program: 'home',
      service: 'member',
      action: 'setLogout',
      version: '1.0'
    }

    // 로그인 정보 및 사이트 정보 가져오기
    getApi("get", params).then( response => {
      // 로그아웃 처리
      dispatch(logout());
      window.localStorage.removeItem('token');

      if( response && response.data.result ) {
        history('/');
      }
    });

  }

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <header className={"header-home " + menuOpen}>
      <div className="header1">
        <div className="inner clearfix">
          <NavLink to="/" className="logo">
            <img src={logo} alt="한국냉동공조산업협회 자격시험센터"/>
          </NavLink>
          <div className="util-menu">
            <ul className="user-menu clearfix">
            {/* {(user === 'Bearer' || user === null) && */}

              {/* 로그인전 */
                user?.mem_no === undefined &&
              <>
                <li><NavLink to="/login" className="usermenu">로그인</NavLink></li>
                <li><NavLink to="/join/step1" className="usermenu">회원가입</NavLink></li>
              </>
              }
              {/* 로그인후 */
                user?.mem_no !== undefined &&
              // {(user !== 'Bearer' && user !== null ) &&
              <>
                <li><div className='usermenu cp' onClick={setLogout}>로그아웃</div></li>
                <li><NavLink to="/mypage/memberModify" className="usermenu">마이페이지</NavLink></li>
              </>
              }
            </ul>
          </div>
        </div>
      </div>
      <div className="header2">
        <div className="inner clearfix">
          <div className="gnb">
            <ul className="menu">

              {
                menuList?.menu?.length > 0 &&
                menuList?.menu?.map((menu, index) => {
                  let check = menu?.children !== undefined ? menu?.children?.filter((item) => item.linkUrl === window.location.pathname ) : '';
                  let isOpen = false;
                  if( check.length > 0 ) isOpen = true;

                  if( parseInt(menu.isMainmenuView) === 0 ) {
                    return <React.Fragment key={index}></React.Fragment>
                  } else {
                    return (
                      <li
                        key={index}
                        className={ isOpen || menu?.seq === menuIndex || menu?.seq === currentMenu?.seq ? 'open' : ''}
                        onClick={(event) => {
                          setMenuIndex(menu.seq);
                          if( menu.children === undefined || menu.children.length < 1 ) {
                            history(menu.linkUrl);
                          }
                        }}
                      >
                        <NavLink exact to={menu.linkUrl+ (menu.queryString ?? '')} className="depth1" onClick={e => e.preventDefault()}>{menu.pageName}</NavLink>

                        <div className="submenu-wrap">
                          <div className="menu-title">{menu.pageName}</div>
                          <ul className="submenu">
                            {
                              menu.children.map((submenu, j) => {
                                if( parseInt(submenu.isMainmenuView) === 0 ) return <React.Fragment key={j}></React.Fragment>

                                return (
                                  <li key={j} className="clearfix">
                                    <NavLink exact to={submenu.linkUrl + (submenu.queryString ?? '')} className="depth2">{submenu.pageName}</NavLink>
                                    <div className="submenu-wrap2">
                                      <ul className="submenu2">
                                        {
                                          submenu.children && submenu.children.map((submenu2, k) => {
                                            if( parseInt(submenu2.isMainmenuView) === 0 ) return <React.Fragment key={k}></React.Fragment>

                                            return (
                                              <li key={k}>
                                                <NavLink exact to={submenu2.linkUrl + (submenu2.queryString ?? '')} className="depth3">{submenu2.pageName}</NavLink>
                                              </li>
                                            )
                                          })
                                        }
                                      </ul>
                                    </div>
                                  </li>
                                )}
                              )
                            }
                          </ul>
                        </div>
                      </li>
                    )
                  }
                })
              }
            </ul>
          </div>
          <Button className="tot-menu" onClick={() => setMenuOpen('open')}></Button>
        </div>
        <Button className="close-menu" onClick={() => setMenuOpen('')}></Button>
      </div>
    </header>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Header;