/*****************************************************************************************
 * 설명 : SubHeader
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';
import { NavLink } from 'react-router-dom';

import './subHeader.scss';


/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const SubHeader = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <div className="sub-header-home">
      <div className="page-title">{props.depth2}</div>
      <ul className="path clearfix">
        <li><NavLink to="/" className="home"></NavLink></li>
        <li className="depth1">{props.depth1}</li>
        <li className="depth2">{props.depth2}</li>
      </ul>
    </div>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default SubHeader;