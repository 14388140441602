/*****************************************************************************************
 * 설명 : 로그인
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { Button } from '@mui/material';
import { CheckBoxEx, InputEx } from "components/inputEx";
import alertMsg from 'components/message';
import { useEffect } from "react";
import { Cookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from "yup";

import Restful from "service/restful";

import { setUserInfo } from 'reducers/userReducer';

import './login.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
function Login(props) {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const cookies = new Cookies()
  const dispatch = useDispatch();
  const [getApi] = Restful();
  const history = useNavigate();

  const formik = useFormik({
    initialValues: {
      id: '',
      pw: '',
      saveId: false
    },
    validationSchema: Yup.object().shape({
      id: Yup.string().min(4, '최소4자').max(20, "20자리").required('필수'),
      pw: Yup.string().min(8, '최소8자').max(16, "16자리").required('필수')
    }),
    onSubmit: (values) => {
      let params = {
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setLogin',
        id: values.id,
        pw: values.pw
      }

      getApi("post", params).then( response => {
        if( response !== undefined && response.data.result ) {
          if( (response.data.data?.dkey ?? '') === '' ) {
            alertMsg(response.data.message, "S", 3000);

            props.history('/login/certificate', {
              state: {
                memNo: response.data.data.memNo,
                userId: response.data.data.id
              }
            });

          } else {
            // 아이디 저장
            if (values.saveId)  {
              cookies.set('CERT_ID', values.id);
            } else
              cookies.remove('CERT_ID');

            alertMsg(response.data.message, "S", 3000);
            dispatch(setUserInfo(response.data.data));
            props.history('/');
          }

        } else {
          alertMsg(response.data.message, "E", 3000);
        }
      });
    }
  });

  /***************************************************************************************
   * 설명 : 아이디 저장 체크
  ***************************************************************************************/
  useEffect(() => {
    const id = cookies.get('CERT_ID');

    if( id !== undefined && id !== 'undefined' ) {
      formik.setFieldValue('id', id);
      formik.setFieldValue('saveId', true);
    }

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <form className="Login" onSubmit={formik.handleSubmit}>
        <div className="login-wrap">
          <div className="login-logo" onClick={() => history('/')}></div>
          <div className="page-title">로그인</div>
          <div className="inputbox">
            <InputEx
              name="id"
              formik={formik}
              placeholder="아이디"
              autoFocus
              variant="outlined"
            />
          </div>
          <div className="inputbox">
            <InputEx
              name="pw"
              type="password"
              formik={formik}
              placeholder="비밀번호"
              variant="outlined"
            />
          </div>
          <div style={{marginLeft: '10px'}}>
            <CheckBoxEx
              name="saveId"
              color="primary"
              formik={formik}
              className="Checkbox"
              label="아이디 저장"
            />
          </div>
          <Button type="submit" variant="contained" className="login-btn">로그인</Button>
          <ul className="login-menu clearfix">
            <li><NavLink to="/join/step1" className="usermenu">회원가입</NavLink></li>
            <li><NavLink to="/findIdPw" className="usermenu">아이디/비밀번호 찾기</NavLink></li>
          </ul>
        </div>
      </form>
    </>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Login;