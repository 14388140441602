/*****************************************************************************************
 * 설명 : 입력폼 컴포넌트
 * 작성자 : 송영석
 * 작성일 : 2021-05-25(화)
 *****************************************************************************************/
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { comma } from 'service/utils';

/*****************************************************************************************
 * 설명 : Button 권한 관련 처리
 * 입력값 : ...props( name, auth 필수 )
 * 리턴값 : html
 *****************************************************************************************/
export function ButtonEx(props) {
  const currentMenu = useSelector(state => state?.menuList?.current);
  const {name, color, className, variant, disabled, type, onClick} = {...props};

  if( (currentMenu[props.auth] ?? '') !== '' ) {
    if( parseInt((currentMenu[props.auth])) === 1 ) {
      return (
        <Button
          name={name}
          type={type}
          color={color}
          className={className}
          variant={variant}
          disabled={disabled}
          onClick={onClick}
        >{props.title}</Button>
      )
    } else {
      return '';
    }
  } else {
    return '';
  }
}

/*****************************************************************************************
 * 설명 : TextField 텍스트 입력 폼
 * 입력값 : ...props( name, formik 필수 )
 * 리턴값 : html
 *****************************************************************************************/
export function InputEx(props) {
  if( props.formik === undefined ) return;

  let className = 'TextField-input' + (props.className !== undefined ? ' ' + props.className : '')
  let style = { flexDirection: 'column !important' }
  if (props.style !== undefined) {
    style = { ...props.style, flexDirection: 'column !important' }
  }

  let inputProps = props.inputProps ? { ...props.inputProps } : {}
  if (props.readonly) inputProps.readOnly = props.readonly

  // subkey object
  let value = props.formik.values[props.name]
  let touched = props.formik.touched[props.name]
  let errors = props.formik.errors[props.name]
  let helperText = props.formik.errors[props.name]

  if (typeof value === 'number') {
    if (props.type === 'string') {
    } else {
      value = comma(value)
    }
  }

  if (props.formik === undefined || props.formik.values === null) {
    console.error('error : ' + props.name + ' not formik')

    return ''
  } else {
    return (
      <TextField
        id={props.name}
        {...props}
        value={value || ''}
        onChange={(e) => {
          if (props.onChange) props.onChange(e)
          props.formik.handleChange(e)

          // min max
          if (e.target.value === '' || props.max === undefined || props.min === undefined) return

          const value = +e.target.value
          if (props.max !== undefined && value > props.max) props.formik.setFieldValue(props.name, props.max)
          else if (props.min !== undefined && value < props.min) props.formik.setFieldValue(props.name, props.min)
          else props.formik.setFieldValue(props.name, value)
        }}
        error={touched && Boolean(errors)}
        helperText={touched && helperText}
        style={style}
        variant="outlined"
        className={className}
        size="small"
        inputProps={inputProps}
        onBlur={props.onBlur}
      />
    )
  }
}

/*****************************************************************************************
 * 설명 : TextareaAutosize 텍스트 입력 폼
 * 입력값 : ...props( name, formik 필수 )
 * 리턴값 : html
 *****************************************************************************************/
export function TextareaEx(props) {
  let className = 'TextAreaField-input'
  let style = { flexDirection: 'column !important' }

  if (props.formik === undefined || props.formik.values === null) {
    console.error('error : ' + props.name + ' not formik')
    return false
  }

  if (props.className !== undefined) {
    className += ' ' + props.className
  }

  if (props.style !== undefined) {
    style = { ...props.style, flexDirection: 'column !important' }
  }

  let value = props.formik.values[props.name]
  let touched = props.formik.touched[props.name]
  let errors = (props.formik.errors[props.name] ?? '') === '' ? false : true;
  let helperText = props.formik.errors[props.name]

  return (
    <TextField
      aria-label="minimum height"
      id={props.name}
      {...props}
      value={value || ''}
      onChange={props.formik.handleChange}
      style={style}
      variant="outlined"
      className={className}
      multiline
//      rows={props.rows ? props.rows : 4}
      minRows={props.minRows ? props.minRows : 10}
      maxRows={props.maxRows ? props.maxRows : 10}
      error={touched && Boolean(errors)}
      helperText={touched && helperText}
    />
  )
}

/*****************************************************************************************
 * 설명 : ToggleButtonGroup 폼
 * 입력값 : ...props( name, formik, data = [{title: '', value: ''}] 필수 )
 * 리턴값 : html
 *****************************************************************************************/
export function ToggleButtonGroupEx(props) {

  let value = props.formik.values[props.name]

  // 버튼 클릭 시 변경 값 적용
  const handleRoleChange = ($event, role) => {
    if (role === null) return false
    if (props.onChange) {
      props.onChange($event, role)
    }
    props.formik.setFieldValue(props.name, role)
  }

  return (
    <>
      <ToggleButtonGroup
        id={props.name}
        value={value}
        {...props}
        onChange={handleRoleChange}
      >
        {props.data.map((item, index) => {
          return (
            <ToggleButton
              type="button"
              key={index}
              value={item.value}
              size="small"
              className={props?.className + " bold"}
            >
              {item.className !== undefined && <span className={item.className}></span>}
              {item.label}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
    </>
  )
}

/*****************************************************************************************
 * 설명 : ToggleButtonGroupRadio 폼
 * 입력값 : ...props( name, formik, data = [{title: '', value: ''}] 필수 )
 * 리턴값 : html
 *****************************************************************************************/
export function ToggleButtonRadioEx(props) {
  if (props.formik === undefined || props.formik.values === null) {
    console.error('error : ' + props.name + ' not formik')
    return false
  }

  let value = props.formik.values[props.name]

  // 버튼 클릭 시 변경 값 적용
  const handleRoleChange = ($event, role) => {
    if (role === null) {
      role = ''
    }

    if (props.onChange) {
      props.onChange($event, role)
    }
    props.formik.setFieldValue(props.name, role)
  }

  const className = props.className !== undefined ? 'toggleBtn ' + props.className : 'toggleBtn '

  // subkey object
  // 주의 : 2단계 object만 지원
  if (props.name.indexOf('.') > -1) {
    let keyField = props.name.substr(0, props.name.indexOf('.'))
    let subField = props.name.substr(props.name.indexOf('.') + 1, props.name.length)

    value = props.formik.values[keyField][subField]
  }

  var className1 = ''
  var groupClassName = ''
  if (props.fullWidth) {
    groupClassName = ' w100per'
    switch (props.data.length) {
      case 1:
        className1 = ' w100per pl0 pr0'
        break
      case 2:
        className1 = ' w50per pl0 pr0'
        break
      case 3:
        className1 = ' w33per pl0 pr0'
        break
      case 4:
        className1 = ' w25per pl0 pr0'
        break
      case 5:
        className1 = ' w20per pl0 pr0'
        break
      default:
        className1 = ''
    }
  }

  let disabled = props.disabled ? props.disabled : false

  return (
    <>
      <FormControl className="radio">
        <RadioGroup
          id={props.name}
          row
          {...props}
          value={value}
          onChange={handleRoleChange}
          className={className + groupClassName}
        >
          {props.data.map((item, index) => {
            return (
              <FormControlLabel
                key={index}
                checked={item.value === props.formik.values[props.name]}
                value={item.value}
                control={<Radio className={className + className1} disabled={disabled} />}
                label={item.label}
              />
            )
          })}
        </RadioGroup>
      </FormControl>
      {/*
      <ButtonGroup
        id={props.name}
        {...props}
        value={value}
        onChange={handleRoleChange}
        className={className + groupClassName}
      >
        {props.data.map((item, index) => {
          return (
            <Button key={index} value={item.value} className={className + className1} disabled={disabled}>
              {item.className !== undefined && <span className={item.className}></span>}
              {item.label}
            </Button>
          )
        })}
      </ButtonGroup>
      */}
    </>
  )
}

/*****************************************************************************************
 * 설명 : Switch 폼
 * 입력값 : ...props( name, formik 필수 )
 * 리턴값 : html
 *****************************************************************************************/
export function SwitchEx(props) {
  if (props.formik === undefined || props.formik.values === null) {
    console.error('error : ' + props.name + ' not formik')
    return false
  }

  let value = props.formik.values[props.name]

  // subkey object
  // 주의 : 2단계 object만 지원
  if (props.name.indexOf('.') > -1) {
    let keyField = props.name.substr(0, props.name.indexOf('.'))
    let subField = props.name.substr(props.name.indexOf('.') + 1, props.name.length)

    value = props.formik.values[keyField][subField]
  }

  let labelCss = ''
  // 2px 위로 올리는 css 적용
  if (value && props.bottom) {
    labelCss = 'switchLabel enable'
  } else if (value && !props.bottom) {
    labelCss = 'switchLabel enable top2'
  } else if (!value && props.bottom) {
    labelCss = 'switchLabel'
  } else if (!value && !props.bottom) {
    labelCss = 'switchLabel top2'
  }

  return (
    <>
      <Switch id={props.name} {...props} checked={value} value={props.value !== null && props.value !== undefined ? props.value : value} onChange={props.formik.handleChange} className={props.bottom ? 'switchUse bottom' : 'switchUse'} disabled={props.disabled} />
      {props.label !== undefined && <span className={labelCss}>{value ? props.label.true : props.label.false}</span>}
    </>
  )
}

/*****************************************************************************************
 * 설명 : NativeSelect 폼
 * 입력값 : ...props( name, formik 필수 )
 * 리턴값 : html
 *****************************************************************************************/
export function SelectEx(props) {
  /*
  let className = 'selectBox';
  if( props.className !== undefined ) {
    className += ' ' + props.className;
  }
  */

  if (props.formik === undefined || props.formik.values === null) {
    console.error('error : ' + props.name + ' not formik')
    return false
  }

  let value = props.formik.values[props.name]
  let touched = props.formik.touched[props.name]
  let errors = props.formik.errors[props.name]

  // subkey object
  // 주의 : 2단계 object만 지원
  if (props.name.indexOf('.') > -1) {
    let keyField = props.name.substr(0, props.name.indexOf('.'))
    let subField = props.name.substr(props.name.indexOf('.') + 1, props.name.length)

    value = props.formik.values[keyField][subField]
    touched = props.formik.touched[keyField]
    errors = props.formik.errors[keyField]
  }

  let data = props.data
  if (data === undefined) {
    data = []
  }

  return (
    <FormControl className="selectBox" fullWidth={props.fullWidth === undefined ? true : props.fullWidth} error={touched && Boolean(errors)}>
      <NativeSelect
        id={props.name}
        {...props}
        checked={value}
        value={value || ''}
        onChange={(event, newValue) => {
          props.formik.handleChange(event, newValue)
          if (props.onChange !== undefined) props.onChange(event, newValue)
        }}
        error={touched && Boolean(errors)}
      >
        {data.map !== undefined &&
          data.map((item, index) => {
            return (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            )
          })}
      </NativeSelect>
      {touched && errors && <div className="error-msg cred">{errors}</div>}
    </FormControl>
  )
}

/*****************************************************************************************
 * 설명 : multi Select 폼
 * 입력값 : ...props( name, formik 필수 )
 * 리턴값 : html
 *****************************************************************************************/
export function SelectMultiEx(props) {
  const onChange = useCallback((e) => {
    props.formik.setFieldValue(props.name, e)

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormControl className={'selectBox'} fullWidth={!props.fullWidth ? true : props.fullWidth}>
      <Select id={props.name} {...props} error={props.formik.touched[props.name] && Boolean(props.formik.errors[props.name])} helperText={props.formik.touched[props.name] && props.formik.errors[props.name]} onChange={onChange} value={[...props.formik.values[props.name]]} />
      {props.formik.touched[props.name] && Boolean(props.formik.errors[props.name]) && <span style={{ color: '#d60000', marginTop: '5px' }}>{props.formik.errors[props.name]}</span>}
    </FormControl>
  )
}

/*****************************************************************************************
 * 설명 : Switch 폼
 * 입력값 : ...props( name, formik 필수 )
 * 리턴값 : html
 *****************************************************************************************/
export function CheckBoxEx(props) {
  const className = props.className !== undefined ? 'Checkbox ' + props.className : 'Checkbox'
  const className1 = props.className !== undefined ? 'CheckboxLabel ' + props.className : 'CheckboxLabel'

  return (
    <FormControlLabel
      control={<Checkbox color={props.color || 'primary'} className={className} />}
      {...props}
      className={className1}
      error={props.formik.touched[props.name] && Boolean(props.formik.errors[props.name]).toString()}
      onChange={props.formik.handleChange}
      checked={props.formik.values[props.name] ? props.formik.values[props.name] : false}
    />
  )
}

/*****************************************************************************************
 * 설명 : 달력 싱글
 *****************************************************************************************/
export function DateEx(props) {
  const value = props.formik.values.hasOwnProperty(props.name) && props.formik.values[props.name] !== null && props.formik.values[props.name].substr !== undefined ? props.formik.values[props.name].substr(0, 10) : props.formik.values[props.name]

  const touched = props.formik.touched[props.name]
  const errors = props.formik.errors[props.name]
  const helperText = props.formik.errors[props.name]

  const type = props.type !== undefined ? 'date' : props.type

  return (
    <TextField
      id={props.name}
      name={props.name}
      label={props.label}
      type={type}
      value={value !== 0 ? value || '' : value}
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={props.formik.handleChange}
      error={touched && Boolean(errors)}
      helperText={touched && helperText}
      style={{ ...props.style, flexDirection: 'column !important' }}
      variant="outlined"
      className={`TextField-input ${props.className || ''}`}
    />
  )
}

/*****************************************************************************************
 * 설명 : 달력 더블
 *****************************************************************************************/
export function DateDoubleEx(props) {
  const startId = props.nameStart
  const endId = props.nameEnd
  const startTag = props.labelStart
  const endTag = props.labelEnd

  const valueStart = props.formik.values[startId]
  const touchedStart = props.formik.touched[startId]
  const errorsStart = props.formik.errors[startId]
  const helperTextStart = props.formik.errors[startId]
  const valueEnd = props.formik.values[endId]
  const touchedEnd = props.formik.touched[endId]
  const errorsEnd = props.formik.errors[endId]
  const helperTextEnd = props.formik.errors[endId]

  return (
    <div
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...props.parentStyle,
      }}
    >
      <TextField
        id={startId}
        label={startTag || ''}
        type="date"
        defaultValue={valueStart || ''}
        sx={{ width: props.width || '100%' }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={props.formik.handleChange}
        error={touchedStart && Boolean(errorsStart)}
        helperText={touchedStart && helperTextStart}
        variant="outlined"
        style={{ width: '150px', ...props.style, flexDirection: 'column !important' }}
        className={`TextField-input ${props.className || ''}`}
      />

      <span style={{ margin: '0 5px' }}> ~ </span>

      <TextField
        id={endId}
        label={endTag || ''}
        type="date"
        defaultValue={valueEnd || ''}
        sx={{ width: props.width || '100%' }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={props.formik.handleChange}
        error={touchedEnd && Boolean(errorsEnd)}
        helperText={touchedEnd && helperTextEnd}
        variant="outlined"
        style={{ width: '150px', ...props.style, flexDirection: 'column !important' }}
        className={`TextField-input ${props.className || ''}`}
      />
    </div>
  )
}
