/*****************************************************************************************
 * 설명 : 회원가입 > 가입완료
 * URI : /join/complete
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';
import { Button } from '@mui/material';

import Layout from 'pages/homepage/layout/layout';

import './join.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const JoinComplete = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="join-home">
        <div className="inner">
          <div className="sub-header clearfix">
            <div className="page-title">회원가입</div>
            <ul className="path clearfix">
              <li>약관동의</li>
              <li>정보입력</li>
              <li className="active">가입완료</li>
            </ul>
          </div>

          <div className="join-complete">

            <div className="complete-div">
              <div className="txt">가입이 완료되었습니다.</div>
              <Button variant="contained" className="HBtn bluebg" href="/login">로그인</Button>
            </div>
          </div>
        </div>
      </div>

   </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default JoinComplete;