/*****************************************************************************************
 * 설명 : 페이지 라우팅
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { IndicatorContext } from 'config/indicator';

import AdminLogin from "pages/admin/login/login";

// // 홈페이지
import Main from "pages/homepage/main/main";

import CompanyLogin from "pages/company/login/login";
import HomeLogin from "pages/homepage/login/login";

import HomeFindIdPw from "pages/homepage/member/findIdPw/findIdPw";
import HomeFindIdResult from "pages/homepage/member/findIdPw/findIdResult";
import HomeFindPwResult from "pages/homepage/member/findIdPw/findPwResult";
import HomeJoinComplete from "pages/homepage/member/join/joinComplete";
import HomeJoinStep1 from "pages/homepage/member/join/joinStep1";
import HomeJoinStep2 from "pages/homepage/member/join/joinStep2";

import HomeBoardView from "pages/homepage/board/boardView";
import Contents from 'pages/homepage/contents/contents';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const SiteRouter = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const { setIndicator } = useContext(IndicatorContext);

  const menuList = useSelector(state => state?.menuList);
  const [moduleList, setModuleList] = useState([]);

  /***************************************************************************************
   * 설명 : 모듈 컴포넌트 로딩
  ***************************************************************************************/
  useEffect(() => {
    if( menuList.list && menuList.list.length > 0 ) {
      let module = [];

      menuList.list.forEach((menu) => {
        if( menu.modulePath && menu.modulePath !== null ) {
          if( menu.isRouter !== 0 && menu.isRouter !== '0' ) {
            const SomeComponent = React.lazy(() => import(`${menu.modulePath}`));

            module.push({
              linkUrl: menu.linkUrl,
              isRouter: menu.isRouter,
              moduleName: menu.moduleName,
              modulePath: menu.modulePath,
              module: SomeComponent
            });
          }
        }
      });

      setModuleList(module);
    }

    // eslint-disable-next-line
  }, [menuList]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Suspense fallback={() => setIndicator(true)}>
      <Routes>
        <Route exact="true" path="/contents" element={<Contents />}></Route>
        { // 관리자 페이지
          window.location.pathname.indexOf('/admin') > -1 &&
          <>
            <Route exact="true" path="/admin" element={<AdminLogin indicator={props.indicator} history={props.history} />}></Route>
            <Route exact="true" path="/admin/login" element={<AdminLogin indicator={props.indicator} history={props.history} />}></Route>
            {
              moduleList && moduleList.length > 0 &&
              moduleList.map((item, index) => {
                if( item.isRouter !== 0 && item.isRouter !== '0' ) {
                  var Module = item.module;
                  return (
                    <Route key={item.seq + '_' + index} exact="true" path={item.linkUrl} element={<Module />}></Route>
                  )
                } else {
                  return <React.Fragment key={index}></React.Fragment>;
                }
              })
            }

            {/*<Redirect path="*" to="/admin/login" />*/}
          </>
        }

        { // 사용자 페이지
          window.location.pathname.indexOf('/admin') < 0 &&
          <>
            <Route exact="true" path="/" element={<Main indicator={props.indicator} history={props.history} />}></Route>
            <Route exact="true" path="/login" element={<HomeLogin indicator={props.indicator} history={props.history} />}></Route>
            <Route exact="true" path="/company" element={<CompanyLogin indicator={props.indicator} history={props.history} />}></Route>

            <Route exact="true" path="/board/boardView" element={<HomeBoardView />}></Route>

            <Route exact="true" path="/findIdPW" element={<HomeFindIdPw />}></Route>
            <Route exact="true" path="/findId/result" element={<HomeFindIdResult />}></Route>
            <Route exact="true" path="/findPw/result" element={<HomeFindPwResult />}></Route>
            <Route exact="true" path="/join/step1" element={<HomeJoinStep1 />}></Route>
            <Route exact="true" path="/join/step2" element={<HomeJoinStep2 />}></Route>
            <Route exact="true" path="/join/complete" element={<HomeJoinComplete />}></Route>
            {
              moduleList && moduleList.length > 0 &&
              moduleList.map((item, index) => {
                var Module = item.module;

                return (
                  <Route key={item.seq + '_' + index} exact="true" path={item.linkUrl} element={<Module />}></Route>
                )
              })
            }
          </>
        }
      </Routes>
    </Suspense>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default SiteRouter;

