/*****************************************************************************************
 * 설명 : 페이지 서브 레이아웃
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';

import Header from "pages/homepage/layout/header";
import Footer from "pages/homepage/layout/footer";

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Layout = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <div className="contents-body-home">
      <Header></Header>
      <section className="contents">
        {props.children}
      </section>
      <Footer></Footer>
    </div>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Layout;