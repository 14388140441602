/*****************************************************************************************
 * 설명 : 아이디/비밀번호 찾기 - 비밀번호찾기결과
 * URI : /findId/result
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";

import Layout from 'pages/homepage/layout/layout';

import Restful from 'service/restful';
import './findIdPw.scss';
// import { useHistory, useLocation } from 'react-router';

import alertMsg from 'components/message';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FindPwResult = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const history = useNavigate();

  const formik = useFormik({
    initialValues: {
      newPw: '',
      pwConfirm: ''
    },
    validationSchema: Yup.object().shape({
      newPw: Yup.string().min(8, '최소8자').max(15, "15자리").required('필수')
      .test(
        'password change check',
        '영문대/소문자,숫자,특수문자 미포함',
        function( str ) {
          const tmp = String(str);
          if( typeof tmp.match !== "function" ) return true;

          return tmp.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&()\-_+=~./])[A-Za-z\d$@$!%*#?&()-_+=~./]{8,16}$/) ? true : false;
        }
      )
      .test(
        'password change check',
        '영문대/소문자,숫자,특수문자',
        function( str ) {
          const tmp = String(str);
          if( typeof tmp.match !== "function" ) return true;

          return tmp.match(/^[A-Za-z0-9$^@$!%*#?&()\-_+=~./]+$/);
        }
      ),
      // pwConfirm: Yup.string().min(8, '최소8자').max(16, "16자리").required('필수').when("newPw", {
      //   is: val => (val && val.length > 0 ? true : false),
      //   then: Yup.string().oneOf(
      //     [Yup.ref("newPw")],
      //     "불일치"
      //   )
      // }),
    }),
    onSubmit: (values) => {
      let params = {
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'pwUpdate',
        newPw: values.newPw,
        // mem_no: location.state.memNo
      }

      getApi("post", params).then( response => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", 3000);
          history('/login');

        } else {
          alertMsg(response.data.message, "E", 3000);
        }
      });
    }
  });

  const onChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value)
  }

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="findIdPw inner">
        <div className="page-title">아이디/비밀번호 찾기</div>

        <div className="result-div">
          <form onSubmit={formik.handleSubmit}>
            <div className="result-wrap">
              <div className="find-title">비밀번호 재설정</div>
              <TextField
                type="password"
                variant="outlined"
                className="input"
                placeholder="새 비밀번호"
                autoFocus
                fullWidth
                name='newPw'
                value={formik.values.newPw}
                helperText={formik.touched['newPw'] && formik.errors["newPw"]}
                error={formik.touched['newPw'] && Boolean(formik.errors['newPw'])}
                onChange={onChange}
              />
              <TextField
                type="password"
                variant="outlined"
                className="input"
                placeholder="새 비밀번호 재입력"
                fullWidth
                name='pwConfirm'
                value={formik.values.pwConfirm}
                helperText={formik.touched['pwConfirm'] && formik.errors["pwConfirm"]}
                error={formik.touched['pwConfirm'] && Boolean(formik.errors['usepwConfirmrName'])}
                onChange={onChange}
              />
              <Button type="submit" variant="contained" className="HBtn blackbg" fullWidth>확인</Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FindPwResult;